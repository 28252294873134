import Col from "react-bootstrap/Col";
import Markdown from "../../shared/Markdown";
import Row from "react-bootstrap/Row";
import React from "react";

type MarkdownDropdownProps = {
    setVisible: (x: boolean) => void,
    markdown: string,
    title?: string,
}

const MarkdownDropdown = ({setVisible, markdown, title}: MarkdownDropdownProps) => {
    return (
        <Row className="mt-3 mb-4">
            <Col>
                <div className="border rounded p-4">
                    <button
                        type="button"
                        className="close float-right"
                        aria-label="Close"
                        onClick={e => setVisible(false)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {title && <h2 className={"mb-4 pb-2 border-bottom"}>{title}</h2>}
                    <Markdown markdown={markdown}/>
                </div>
            </Col>
        </Row>
    )
}

export default MarkdownDropdown;
