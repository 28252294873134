import React from "react";

function TestResult({test}: { test: { result: string, fullName: string, logs: any[], err: string, stack: string[] } }) {
    let classNames: { [key: string]: string } = {
        "passed": "text-success",
        "failed": "text-danger",
    };
    let className: string = classNames[test.result] || "";

    return (
        <div className={`p-2 rounded border mb-2 ${className || ""}`}>
            <p>
                <strong>{test.fullName} &mdash; {test.result}</strong>
            </p>
            {
                test.logs.length > 0 && (
                    <>
                        <p>
                            <code>console.log</code>&nbsp;messages:
                        </p>
                        {
                            test.logs.map((log, logIdx) => (
                                <div key={logIdx}>
                                    <pre><code>{log}</code></pre>
                                </div>
                            ))
                        }
                    </>
                )
            }
            {
                test.err && (
                    <p>{test.err}</p>
                )
            }
            {
                test.stack && test.stack.length && (
                    <>
                        <div>
                          <pre>
                            <code>{test.stack.map((line, stackIdx) => (<div key={stackIdx}>{line}</div>))}</code>
                          </pre>
                        </div>
                    </>
                )
            }
        </div>
    );
}

function TestResultList({data: {stats, tests}}: {data: {stats: any, tests: any[]}}) {
    return (
        <div>
            <div className={"p-2 rounded border mt-3 mb-3"}>
                Tests: {stats.tests}&nbsp;&ndash;&nbsp;
                <span className={"text-success"}>Passes: {stats.passes}</span>&nbsp;&ndash;&nbsp;
                <span className={"text-danger"}>Failures: {stats.failures}</span>&nbsp;&ndash;&nbsp;
                <span className={"text-warning"}>Pending: {stats.pending}</span>
            </div>
            {tests.map((test, i) => <TestResult key={i} test={test}/>)}
        </div>
    );
}

export default TestResultList;
