import { combineReducers, configureStore } from '@reduxjs/toolkit';
import auth from '../features/auth/auth-slice';
import toast from '../features/toast/toast-slice';

let reducer = {
    auth,
    toast,
};
let store = configureStore({reducer});

const rootReducer = combineReducers(reducer);
export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export default store;
