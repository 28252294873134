import React from "react";
import { useSelector } from "react-redux";
import {
    selectCurrentUserSet,
    selectLoggedIn,
    selectLoggedInAdmin,
    selectLoggedInManager
} from "../features/auth/auth-slice";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Login from "../features/auth/Login";
import Container from "react-bootstrap/Container";
import { Redirect } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const MarketingHome = () => {
    return (
        <Container className={"mt-5"}>
            <Row>
                <Col>
                    <h1>Learn new skills faster with Crux coding workouts</h1>
                    <p className="lead">As a professional developer, keeping your skills sharp is a necessity. Crux uses
                        the
                        principles of deliberate practice to help you make the most of your limited learning time and
                        hone
                        the essential skills for staying relevant in a rapidly changing world.</p>
                    <ul>
                        <li>Tailored goal setting</li>
                        <li>Clear practice objectives</li>
                        <li>Immediate feedback</li>
                        <li>Repetition</li>
                        <li>Motivation</li>
                    </ul>
                </Col>
                <Col>
                    <Login/>
                </Col>
            </Row>
        </Container>
    );
};

const Home = () => {
    const loggedIn = useSelector(selectLoggedIn);
    const loggedInAdmin = useSelector(selectLoggedInAdmin);
    const loggedInManager = useSelector(selectLoggedInManager);
    const currentUserSet = useSelector(selectCurrentUserSet);

    if (!currentUserSet) {
        return <Spinner animation={"border"}/>;
    } else if (loggedInAdmin) {
        return <Redirect to={"/admin/dashboard"}/>;
    } else if (loggedInManager) {
        return <Redirect to={"/manager-dashboard"}/>;
    } else if (loggedIn) {
        return <Redirect to={"/student-dashboard"}/>;
    } else {
        return <MarketingHome/>;
    }
};

export default Home;
