import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";

firebase.initializeApp({
    apiKey: "AIzaSyDNOA2CD6mg6uae3slCXYjbYQ59b8CmyOg",
    authDomain: "upslope-crux.firebaseapp.com",
    projectId: "upslope-crux",
    storageBucket: "upslope-crux.appspot.com",
    messagingSenderId: "456108870933",
    appId: "1:456108870933:web:e81528fcfec7f50d506d1c",
    measurementId: "G-9J3NHRHKML"
});

export const auth = firebase.auth();
export const db = firebase.firestore();
export const functions = firebase.functions();
export const githubProvider = new firebase.auth.GithubAuthProvider();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const FieldValue = firebase.firestore.FieldValue;
export const FieldPath = firebase.firestore.FieldPath;
export const Timestamp = firebase.firestore.Timestamp;

let _logEvent: any = () => {};
let _setUserProperties: any = () => {};
let _setCurrentScreen: any = () => {};
if (process.env.NODE_ENV !== "test") {
    let analytics = firebase.analytics();
    _logEvent = analytics.logEvent;
    _setUserProperties = analytics.setUserProperties;
    _setCurrentScreen = analytics.setCurrentScreen;
}
export const logEvent = _logEvent;
export const setUserProperties = _setUserProperties;
export const setCurrentScreen = _setCurrentScreen;

// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost") {
    functions.useEmulator("localhost", 4031);
    // @ts-ignore
    auth.useEmulator('http://localhost:4030/', { disableWarnings: true });
    db.useEmulator("localhost", 4032);
}
