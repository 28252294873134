import { Document, Lesson, LessonWithExercises } from "../../../../types";
import { db, FieldPath } from "../../services/firebase";
import firebase from "firebase/compat";

export const fetchLessons = async (): Promise<Document<Lesson>[]> => {
    const querySnapshot = await db.collection("lessons").get() as firebase.firestore.QuerySnapshot<Lesson>;
    const lessons: Document<Lesson>[] = [];
    querySnapshot.forEach(doc => lessons.push({id: doc.id, data: doc.data()}));
    return lessons;
};

export const fetchLesson = async (lessonId: string): Promise<Document<LessonWithExercises> | null> => {
    const document = await db.collection("lessons").doc(lessonId).get() as firebase.firestore.DocumentSnapshot<Lesson>;
    if (!document.exists) return null;

    const exerciseIds = document.data()!.exerciseIds;

    const exercises: any = {};
    for (const group of inGroupsOf(exerciseIds, 10)) {
        const exerciseQuerySnapshot = await db.collection("exercises")
                                              .where(FieldPath.documentId(), 'in', group)
                                              .get();
        exerciseQuerySnapshot.forEach(doc => exercises[doc.id] = {id: doc.id, data: doc.data()});
    }

    return {
        id: document.id,
        data: {
            ...document.data() as Lesson,
            exercises: exerciseIds.map(id => exercises[id])
        }
    };
};

const inGroupsOf = (things: any[], size: number) => {
    let countGrouped = 0;
    return things.reduce((result, thing) => {
        countGrouped++;
        const last = result[result.length - 1];
        last.push(thing);
        if (last.length === size && countGrouped < things.length) {
            result.push([]);
        }
        return result;
    }, [[]]);
};
