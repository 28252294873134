import { DateTime } from "luxon";
import { Client, FirestoreClient } from "../../../../types";
import firebase from "firebase/compat/app";

export const clientConverter = {
    toFirestore(client: Client) {
        return client;
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot<FirestoreClient>,
        options: firebase.firestore.SnapshotOptions
    ): Client {
        const data = snapshot.data(options);
        return {
            ...data,
            createdAt: data.createdAt ? DateTime.fromJSDate(data.createdAt.toDate()).toISO() : undefined
        };
    }
};

