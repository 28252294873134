import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MultipleChoice from "./MultipleChoice";
import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/auth-slice";
import { db } from "../../services/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { Tab, Tabs } from "react-bootstrap";
import Markdown from "../../shared/Markdown";
import { enrollmentContext, lessonContext } from "../../components/contexts";
import { Document, Exercise, MultipleChoiceExercise } from "../../../../types";

const navBarHeight = "56px";
const footerHeight = "56px";
const tabHeight = "50px";

type MultipleChoiceExerciseProps = {
    exercise: Document<Exercise>,
    enrollmentExercise: any,
    previousUrl: string,
    nextUrl: string
}

function MultipleChoiceExerciseComponent({
                                             exercise,
                                             enrollmentExercise,
                                             previousUrl,
                                             nextUrl
                                         }: MultipleChoiceExerciseProps) {
    const currentUser = useSelector(selectCurrentUser);
    const {lesson} = useContext(lessonContext);
    const {enrollment} = useContext(enrollmentContext);
    const answer = enrollmentExercise.answer;

    const onAnswered = async (answer: string, correct: boolean) => {
        const data = {
            attempts: {
                [enrollment!.data.currentAttempt]: {
                    exercises: {
                        [exercise.id]: {
                            type: exercise.data.type,
                            answer: answer,
                            correct
                        }
                    }
                }
            }
        };
        await db.collection("enrollments").doc(`${currentUser!.id}:${lesson!.id}`).set(data, {merge: true});
    };

    return (
        <Container fluid>
            <Row>
                <Col md={{offset: 3, span: 6}} className={"pt-2"}>
                    <Tabs>
                        <Tab eventKey="question"
                             style={{
                                 "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight})`,
                                 "overflow": "auto"
                             }}
                             title="Question"
                             className={"pt-4 pr-2"}>
                            <div className={"border rounded p-5"}>
                                <MultipleChoice exercise={exercise as Document<MultipleChoiceExercise>}
                                                answeredAlready={!!answer}
                                                onAnswered={onAnswered}/>
                            </div>
                        </Tab>
                        {
                            lesson!.data.cheatSheet && (
                                <Tab eventKey="cheatsheet"
                                     title="Cheat Sheet"
                                     style={{
                                         "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight})`,
                                         "overflow": "auto"
                                     }}
                                     className={"pt-2 pr-2"}>
                                    <Markdown markdown={lesson!.data.cheatSheet}/>
                                </Tab>
                            )
                        }
                    </Tabs>
                </Col>
            </Row>
            <Row style={{height: footerHeight}} className={"navbar-themed pt-2 border-top"}>
                <Col xs={3} className={"text-left"}>
                </Col>
                <Col xs={6} className={"text-center"}>
                    {
                        previousUrl && (
                            <Link style={{width: "10em"}} className={"btn btn-warning d-inline-block mr-5"}
                                  to={previousUrl}>
                                <FontAwesomeIcon icon={faChevronLeft}/> Previous
                            </Link>
                        )
                    }
                    {
                        !previousUrl && (
                            <button style={{width: "10em"}} disabled
                                    className={"btn btn-warning d-inline-block mr-5"}>
                                <FontAwesomeIcon icon={faChevronLeft}/> Previous
                            </button>
                        )
                    }
                    {
                        nextUrl && (
                            <Link style={{width: "10em"}} className={"btn btn-warning d-inline-block ml-5"}
                                  to={nextUrl}>
                                Next <FontAwesomeIcon icon={faChevronRight}/>
                            </Link>
                        )
                    }
                    {
                        !nextUrl && (
                            <button style={{width: "10em"}} disabled
                                    className={"btn btn-warning d-inline-block ml-5"}>
                                Next <FontAwesomeIcon icon={faChevronRight}/>
                            </button>
                        )
                    }
                </Col>
                <Col xs={3} className={"text-right"}>
                </Col>
            </Row>
        </Container>
    );
}

export default MultipleChoiceExerciseComponent;
