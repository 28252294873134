import { db, FieldValue } from "../../services/firebase";
import {
    Attendance,
    CFUType,
    CurrentUser,
    Document,
    Enrollment,
    Lesson,
    LessonIdentifier,
    MultipleChoiceExercise,
    Submission,
    Workshop
} from '../../../../types';
import { attendanceConverter, enrollmentConverter } from "./converters";

export const fetchOrCreateAttendance = async (workshopId: string, user: CurrentUser): Promise<Document<Attendance>> => {
    const attendanceRef = db.collection("workshops").doc(workshopId)
                            .collection("attendances").doc(user.id)
                            .withConverter(attendanceConverter);

    const attendanceSnapshot = await attendanceRef.get();
    if (attendanceSnapshot.exists) {
        return {
            id: attendanceSnapshot.id,
            data: attendanceSnapshot.data()!
        };
    } else {
        const attendance: Attendance = {
            user: {
                id: user.id,
                displayName: user.displayName,
                email: user.email
            },
            cfus: {},
            exercises: {},
        };
        await attendanceRef.set(attendance);
        return {
            id: attendanceRef.id,
            data: attendance
        };
    }
};

export const fetchOrCreateEnrollment = async (user: CurrentUser, lesson: Document<LessonIdentifier>): Promise<Document<Enrollment>> => {
    let enrollmentRef = db.collection("enrollments").doc(`${user.id}:${lesson.id}`);
    const snapshot = await enrollmentRef.withConverter(enrollmentConverter).get();
    if (snapshot.exists) {
        return {id: snapshot.id, data: snapshot.data() as Enrollment};
    } else {
        const enrollment: Enrollment = {
            user: {
                id: user.id,
                displayName: user.displayName,
                email: user.email,
            },
            lesson: {
                id: lesson.id,
                name: lesson.data.name,
            },
            status: "started",
            currentAttempt: "1",
            attempts: {
                "1": {
                    status: "started",
                    exercises: {}
                }
            }
        };
        await enrollmentRef.set(enrollment);
        return {id: enrollmentRef.id, data: enrollment};
    }
};

export const fetchEnrollmentsForUser = async (user: CurrentUser) => {
    let enrollmentsRef = db.collection("enrollments").where("user.id", "==", user.id);
    const snapshot = await enrollmentsRef.withConverter(enrollmentConverter).get();
    const enrollments: Document<Enrollment>[] = [];
    snapshot.forEach(doc => enrollments.push({id: doc.id, data: doc.data()}));
    return enrollments;
};

export const fetchEnrollmentsForLesson = async (lessonId: string) => {
    let enrollmentsRef = db.collection("enrollments").where("lesson.id", "==", lessonId);
    const snapshot = await enrollmentsRef.withConverter(enrollmentConverter).get();
    const enrollments: Document<Enrollment>[] = [];
    snapshot.forEach(doc => enrollments.push({id: doc.id, data: doc.data()}));
    return enrollments;
};

export const createSubmission = async ({
    user,
    clientId,
    exerciseId,
    lessonId,
    workshopId,
    files,
    exerciseType,
    language,
    currentAttempt,
    code,
    tests,
    testResults
}: Submission) => {
    let data: Submission = {
        user: {
            id: user.id,
            displayName: user.displayName,
            email: user.email,
        },
        exerciseId,
        exerciseType,
        lessonId,
        language,
        currentAttempt,
        testResults,
        createdAt: FieldValue.serverTimestamp()
    };
    if (clientId) data.clientId = clientId;
    if (workshopId) data.workshopId = workshopId;
    switch (exerciseType) {
        case "code-with-tests":
        case "tdd":
            data.code = code;
            data.tests = tests;
            break;
        case "piston":
            data.code = code;
            break;
        case "stackblitz":
            data.files = files;
            break;
        default:
            break;
    }

    console.log("submission", data);

    await db.collection("submissions").add(data);
};

type Verifiers = {
    [key in CFUType]: (exercise: Document<MultipleChoiceExercise>, answer: string) => boolean;
};
export const answerVerifiers: Verifiers = {
    "multiple-choice": (exercise, answer) => {
        return answer === exercise.data.inputs.find(input => input.correct)!.answer;
    },
};

export const submitAnswer = async (
    workshop: Document<Workshop>,
    enrollment: Document<Enrollment>,
    user: CurrentUser,
    exercise: Document<MultipleChoiceExercise>,
    answer: string
) => {
    const verifier = answerVerifiers[exercise.data.type as CFUType];
    if (!verifier) throw new Error("Cannot verify answer");
    const correct = verifier(exercise, answer);

    await db.collection("workshops").doc(workshop.id)
            .collection("attendances").doc(user.id)
            .update({
                [`cfus.${exercise.id}`]: {answer, correct}
            });

    const data = {
        attempts: {
            [enrollment.data.currentAttempt]: {
                exercises: {
                    [exercise.id]: {
                        type: exercise.data.type,
                        answer: answer,
                        correct
                    }
                }
            }
        }
    };

    await db.collection("enrollments").doc(enrollment.id).set(data, {merge: true});
};
