import React, { useEffect, useState } from "react";
import { Document, Enrollment, EnrollmentStatus, Lesson, Workshop } from "../../../types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import Button from "react-bootstrap/Button";
import { fetchWorkshopsForClient } from "../features/workshops/workshop-api";
import { fetchLessons } from "../features/lessons/lesson-api";
import { selectCurrentUser } from "../features/auth/auth-slice";
import { fetchEnrollmentsForUser } from "../features/students/student-api";

function StudentDashboard() {
    const currentUser = useSelector(selectCurrentUser)!;
    const [workshops, setWorkshops] = useState<Document<Workshop>[]>([]);
    const [lessons, setLessons] = useState<Document<Lesson>[]>([]);
    const [enrollments, setEnrollments] = useState<Document<Enrollment>[]>([]);

    useEffect(() => {
        (async () => {
            if (currentUser.claims && currentUser.claims.clients) {
                setWorkshops(await fetchWorkshopsForClient(Object.keys(currentUser.claims.clients)));
                setLessons(await fetchLessons());
                setEnrollments(await fetchEnrollmentsForUser(currentUser));
            }
        })();
    }, [currentUser]);

    const upcomingWorkshops = workshops.filter(workshop => workshop.data.status !== "delivered");

    if (!upcomingWorkshops.length && !lessons.length) return <Container className={"mt-5"}>
        <Spinner animation={"border"}/>
    </Container>;

    const statusesByLesson = enrollments.reduce((result: { [key: string]: EnrollmentStatus }, enrollment) => {
        result[enrollment.data.lesson.id] = enrollment.data.status;
        return result;
    }, {});

    const workshopsByClient: { [key: string]: object[] } = upcomingWorkshops.reduce((result: { [key: string]: Document<Workshop>[] }, workshop) => {
        result[workshop.data.client.name] = result[workshop.data.client.name] || [];
        result[workshop.data.client.name].push(workshop);
        return result;
    }, {});

    const groupedWorkshopsByClient = Object.entries(workshopsByClient).reduce((result: any, [clientName, workshops]) => {
        result[clientName] = workshops.reduce((inner: any[], workshop) => {
            if (!inner.length) inner.push([]);
            const group = inner[inner.length - 1];
            group.push(workshop);
            if (group.length === 4) inner.push([]);
            return inner;
        }, []);
        return result;
    }, {});

    const lessonGroups: Document<Lesson>[][] = lessons.reduce((result: any, lesson) => {
        const group = result[result.length - 1];
        if (process.env.REACT_APP_SHOW_DRAFT_LESSONS === "true" || lesson.data.status === "published") {
            group.push(lesson);
        }
        if (group.length === 4) result.push([]);
        return result
    }, [[]]);

    return (
        <Container className={"mt-5"}>
            <div className={"mb-5"}>
                {
                    Object.entries(groupedWorkshopsByClient).map((entry: [string, any]) => {
                        const [clientName, workshopGroups] = entry;
                        return (
                            <div key={clientName}>
                                <h2 className={"mb-4"}>Workshops for {clientName}</h2>
                                {
                                    workshopGroups.map((workshops: Document<Workshop>[], i: number) => (
                                        <CardDeck key={i}>
                                            {
                                                workshops.map(workshop => (
                                                    <Card key={workshop.id}
                                                          style={{maxWidth: "calc(50% - 2em)", minHeight: "16rem"}}
                                                          className={"mb-3"}>
                                                        <Card.Body>
                                                            <Card.Title>
                                                                <Link to={`/workshops/${workshop.id}`}>
                                                                    {workshop.data.lesson.name} {workshop.data.date}
                                                                </Link>
                                                            </Card.Title>
                                                            <Card.Text>
                                                                {workshop.data.summary}
                                                            </Card.Text>
                                                            <Button as={Link}
                                                                    to={`/workshops/${workshop.id}`}
                                                                    variant="info">Join</Button>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                        </Card.Footer>
                                                    </Card>
                                                ))
                                            }
                                        </CardDeck>
                                    ))
                                }
                            </div>
                        );
                    })
                }
            </div>

            <div>
                <h2 className={"mb-4"}>Lessons</h2>
                {
                    lessonGroups.map((lessons, i) => (
                        <CardDeck key={i}>
                            {
                                lessons.map(lesson => {
                                    let callToAction;
                                    let variant;
                                    switch (statusesByLesson[lesson.id]) {
                                        case "complete":
                                            callToAction = "Go Again";
                                            variant = "info";
                                            break;
                                        case "in-progress":
                                            callToAction = "Resume";
                                            variant = "success";
                                            break;
                                        default:
                                            callToAction = "Start";
                                            variant = "primary";
                                    }

                                    return (
                                        <Card key={lesson.id} style={{maxWidth: "calc(25% - 2em)", minHeight: "16rem"}}
                                              className={"mb-3"}>
                                            <Card.Body>
                                                <Card.Title>
                                                    <Link to={`/lessons/${lesson.id}`}>
                                                        {lesson.data.name}
                                                    </Link>
                                                </Card.Title>
                                                <Card.Text>
                                                    {lesson.data.summary}
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer className={"text-center"}>
                                                <Button as={Link}
                                                        className={"w-75"}
                                                        to={`/lessons/${lesson.id}`}
                                                        variant={variant}>{callToAction}</Button>
                                            </Card.Footer>
                                        </Card>
                                    );
                                })
                            }
                        </CardDeck>
                    ))
                }
            </div>
        </Container>
    );
}

export default StudentDashboard;
