import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchWorkshopAsInstructor, watchAttendances } from "./workshop-api";
import { Card, ProgressBar } from "react-bootstrap";
import Markdown from "../../shared/Markdown";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { db, FieldValue } from "../../services/firebase";
import Container from "react-bootstrap/Container";
import { Attendance, DeliveredExercise, Document, Exercise, LessonWithExercises, MultipleChoiceExercise, Workshop } from "../../../../types";
import SingleLineMarkdown from "../../shared/SingleLineMarkdown";
import Table from "react-bootstrap/Table";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCircle } from "@fortawesome/free-solid-svg-icons/faCircle";
import firebase from "firebase/compat";
import { deliveredExerciseConverter } from "./workshop-converters";
import { fetchLesson } from "../lessons/lesson-api";

function getCfuCounts(deliveredExercises: DeliveredExercise, cfu: any, attendances: Document<Attendance>[]) {
    const assigned = deliveredExercises.deliveredCfus.includes(cfu.id);
    const studentCount = attendances.length;
    const counts = attendances
        .map(attendance => attendance.data.cfus[cfu.id])
        .filter(response => response)
        .reduce((result, response) => {
            response.correct ? result.correct++ : result.incorrect++;
            return result;
        }, {correct: 0, incorrect: 0});
    return {assigned, studentCount, counts};
}

function getExerciseCounts(exercise: Document<Exercise>, attendances: Document<Attendance>[]) {
    const studentCount = attendances.length;
    const counts = attendances
        .map(attendance => attendance.data.exercises[exercise.id])
        .filter(response => response)
        .reduce((result, response) => {
            response.status === "complete" ? result.complete++ : result.inProgress++;
            return result;
        }, {complete: 0, inProgress: 0});
    return {studentCount, counts};
}

function CFUProgressBar({studentCount, counts}: {studentCount: number, counts: {correct: number, incorrect: number}}) {
    if (counts.correct === studentCount) {
        return <ProgressBar key={2}
                            min={0}
                            max={studentCount}
                            now={counts.correct} variant="success"/>;
    } else if (counts.incorrect === studentCount) {
        return <ProgressBar key={2}
                            min={0}
                            max={studentCount}
                            now={counts.incorrect} variant="danger"/>;
    } else {
        return <ProgressBar>
            <ProgressBar key={1}
                         min={0}
                         max={studentCount}
                         now={counts.correct}
                         variant="success"/>
            <ProgressBar key={2} min={0}
                         max={studentCount}
                         now={counts.incorrect}
                         variant="danger"/>
        </ProgressBar>;
    }
}

function ExerciseProgressBar({studentCount, counts}: {studentCount: number, counts: {complete: number, inProgress: number}}) {
    if (counts.complete === studentCount) {
        return <ProgressBar key={2}
                            min={0}
                            max={studentCount}
                            now={counts.complete} variant="success"/>;
    } else if (counts.inProgress === studentCount) {
        return <ProgressBar key={2}
                            min={0}
                            max={studentCount}
                            now={counts.inProgress} variant="warning"/>;
    } else {
        return <ProgressBar>
            <ProgressBar key={1}
                         min={0}
                         max={studentCount}
                         now={counts.complete}
                         variant="success"/>
            <ProgressBar key={2} min={0}
                         max={studentCount}
                         now={counts.inProgress}
                         variant="warning"/>
        </ProgressBar>;
    }
}

type CFUCardProps = {
    cfu: Document<MultipleChoiceExercise>,
    assign: any,
    deliveredExercises: any,
    attendances: any
};
function CFUCard({cfu, assign, deliveredExercises, attendances}: CFUCardProps) {
    const {assigned, studentCount, counts} = getCfuCounts(deliveredExercises, cfu, attendances);

    return <Card className={"mb-3"}>
        <Card.Header>
            <div className="float-right">
                {!assigned && (<Button onClick={assign} size={"sm"}>Assign</Button>)}
                {assigned && (<div className="text-success">Already assigned √</div>)}
            </div>
            {cfu.id}
        </Card.Header>
        <Card.Body>
            <Markdown markdown={cfu.data.description}/>
            <div>
                <ul>
                    {
                        cfu.data.inputs.map(input => {
                            const className = input.correct ? "text-success" : "";
                            return (
                                <li key={input.answer} className={className}>
                                    <SingleLineMarkdown markdown={input.answer}/>
                                    {input.correct && " (correct)"}
                                </li>
                            );
                        })
                    }
                </ul>

                {
                    assigned && <CFUProgressBar studentCount={studentCount} counts={counts}/>
                }
            </div>
        </Card.Body>
    </Card>;
}

function InstructorWorkshopUI() {
    const params = useParams<{workshopId: string}>();
    const [workshop, setWorkshop] = useState<Document<Workshop> | null>(null);
    const [lesson, setLesson] = useState<Document<LessonWithExercises> | null>(null);
    const [attendances, setAttendances] = useState<Document<Attendance>[]>([]);
    const [deliveredExercises, setDeliveredExercises] = useState<DeliveredExercise>({deliveredCfus: []});
    const [selectedCfuId, setSelectedCfuId] = useState<string | null>(null);
    const [anonymousMode, setAnonymousMode] = useState(true);

    useEffect(() => {
        (async () => {
            const workshop = await fetchWorkshopAsInstructor(params.workshopId);
            const lesson = await fetchLesson(workshop.data.lesson.id);
            setWorkshop(workshop);
            setLesson(lesson);
        })();
        let unsubscribeAttendances = watchAttendances(params.workshopId, attendances => setAttendances(attendances));
        let unsubscribeDeliveredExercises = db.collection("workshops").doc(params.workshopId)
                                              .collection("live").doc("delivered-exercises")
                                              .withConverter(deliveredExerciseConverter)
                                              .onSnapshot((snapshot: firebase.firestore.DocumentSnapshot<DeliveredExercise>) => {
                                                  setDeliveredExercises({
                                                      deliveredCfus: snapshot.data()!.deliveredCfus || []
                                                  });
                                              });

        return () => {
            unsubscribeAttendances();
            unsubscribeDeliveredExercises();
        };
    }, [params.workshopId]);

    if (!(workshop && lesson && lesson.data.exercises)) return <div>Loading...</div>;

    const exercises = lesson!.data.exercises;
    console.log(exercises);
    const cfus = exercises.filter(exercise => exercise.data.type === "multiple-choice");
    const codeExercises = exercises.filter(exercise => exercise.data.type !== "multiple-choice");

    const userId = (attendance: Document<Attendance>) => {
        const name = attendance.data.user.displayName || attendance.data.user.email || "unknown";
        return anonymousMode ? `User-${Buffer.from(name).toString('hex').slice(0, 5)}` : name;
    }

    const cfuTable = attendances.map((attendance) => {
        const row: {user: string, cfus: any[]} = {user: userId(attendance), cfus: []};
        cfus.forEach(cfu => {
            row.cfus.push({id: cfu.id, answered: attendance.data.cfus[cfu.id], ...attendance.data.cfus[cfu.id]});
        })
        return row;
    });

    const exerciseTable = attendances.map((attendance) => {
        const row: {user: string, exercises: any[]} = {user: userId(attendance), exercises: []};
        codeExercises.forEach(exercise => {
            row.exercises.push({id: exercise.id, ...attendance.data.exercises[exercise.id]});
        })
        return row;
    });

    return (
        <Container fluid className={"mt-4"}>
            <Row>
                <Col>
                    <h2 className={"mb-4 border-bottom pb-2"}>CFUs</h2>
                    <Table size={"sm"} bordered striped>
                        <thead>
                        <tr>
                            <th>Student</th>
                            {cfus.map(cfu => (
                                <th key={cfu.id}
                                    className={"clickable"}
                                    onClick={e => cfu.id === selectedCfuId ? setSelectedCfuId(null) : setSelectedCfuId(cfu.id)}>
                                    {cfu.data.name}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        {
                            !!selectedCfuId && (
                                <tbody>
                                <tr>
                                    <td colSpan={cfus.length + 1}>
                                        <CFUCard key={selectedCfuId}
                                                 cfu={cfus.find(cfu => cfu.id === selectedCfuId) as Document<MultipleChoiceExercise>}
                                                 deliveredExercises={deliveredExercises}
                                                 attendances={attendances}
                                                 assign={async () => {
                                                     await db.collection("workshops").doc(workshop.id)
                                                             .collection("live").doc("delivered-exercises")
                                                             .set({
                                                                 clientId: workshop.data.client.id,
                                                                 deliveredCfus: FieldValue.arrayUnion(selectedCfuId)
                                                             }, {merge: true});
                                                 }}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            )
                        }

                        <tbody>
                        {
                            cfuTable.map((row, i) => {
                                return (
                                    <tr key={`cfu-${row.user}`}>
                                        <th>{row.user}</th>
                                        {
                                            row.cfus.map((cfu, i) => {
                                                if (cfu.answered) {
                                                    const {correct} = cfu;
                                                    const className = correct ? "text-center text-success" : "text-center text-danger";
                                                    const icon = correct
                                                        ? <FontAwesomeIcon icon={faCheck}/>
                                                        : <FontAwesomeIcon icon={faTimes}/>
                                                    return (
                                                        <td key={`${row.user}-${cfu.id}`}
                                                            className={className}>
                                                            {icon}
                                                        </td>
                                                    )
                                                } else {
                                                    return <td key={`${row.user}-${i}`}/>
                                                }
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        </tbody>

                        <tfoot>
                        <tr>
                            <td/>
                            {
                                cfus.map(cfu => {
                                    const cfuCounts = getCfuCounts(deliveredExercises, cfu, attendances);
                                    return (
                                        <td key={`${cfu.id}-progress`}>
                                            {
                                                cfuCounts.assigned && (
                                                    <CFUProgressBar studentCount={cfuCounts.studentCount}
                                                                    counts={cfuCounts.counts}/>
                                                )
                                            }
                                            {
                                                !cfuCounts.assigned && (
                                                    <ProgressBar key={2}
                                                                 min={0}
                                                                 max={cfuCounts.studentCount}
                                                                 now={0} variant="success"/>
                                                )
                                            }
                                        </td>
                                    );
                                })
                            }
                        </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className={"mb-4 border-bottom pb-2"}>Exercises</h2>

                    <Table size={"sm"} bordered striped>
                        <thead>
                        <tr>
                            <th>Student</th>
                            {codeExercises.map(exercise => (
                                <th key={exercise.id}>
                                    {exercise.data.name}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            exerciseTable.map((row, i) => {
                                return (
                                    <tr key={`exercise-${row.user}`}>
                                        <th>{row.user}</th>
                                        {
                                            row.exercises.map((exercise, i) => {
                                                let className = "text-center";
                                                let icon = <span>&nbsp;</span>;

                                                if (exercise.status === "complete") {
                                                    className += " text-success";
                                                    icon = <FontAwesomeIcon icon={faCheck}/>
                                                } else if (exercise.status === "in-progress") {
                                                    className += " text-warning";
                                                    icon = <FontAwesomeIcon icon={faCircle}/>
                                                }

                                                return (
                                                    <td key={`exercise-${row.user}-${exercise.id}`}
                                                        className={className}>
                                                        {icon}
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        </tbody>

                        <tfoot>
                        <tr>
                            <td/>
                            {
                                codeExercises.map(exercise => {
                                    const exerciseCounts = getExerciseCounts(exercise, attendances);
                                    return (
                                        <td key={`exercise-${exercise.id}-progress`}>
                                            <ExerciseProgressBar studentCount={exerciseCounts.studentCount}
                                                                 counts={exerciseCounts.counts}/>
                                        </td>
                                    );
                                })
                            }
                        </tr>
                        </tfoot>
                    </Table>

                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className={"mb-4 border-bottom pb-2"}>Progress By User</h2>
                    {
                        attendances.map(attendance => {
                            return (<div key={attendance.id}>
                                <p>
                                    <strong>{userId(attendance)}</strong>
                                </p>
                                <p><strong>CFUs</strong></p>
                                <ul>
                                    {
                                        (Object.entries(attendance.data.cfus) as [string, any][]).map(([id, data]) => {
                                            return (
                                                <li key={[attendance.id, id].join('-')}>
                                                    {id} &mdash; {data.answer} ({data.correct ? "correct" : "incorrect"})
                                                </li>);
                                        })
                                    }
                                </ul>
                                <p><strong>Exercises</strong></p>
                                <ul>
                                    {
                                        (Object.entries(attendance.data.exercises) as [string, any][]).map(([id, data]) => {
                                            return (
                                                <li key={[attendance.id, id].join('-')}>
                                                    {
                                                        data.stats && data.stats.user && (
                                                            <>
                                                                User:&nbsp;
                                                                <span className={"text-success"}>
                                                                    {data.stats.user.pass} Passing
                                                                </span>
                                                                &nbsp;&mdash;&nbsp;
                                                                <span className={"text-danger"}>
                                                                    {data.stats.user.fail} Failing
                                                                </span>
                                                            </>
                                                        )
                                                    }
                                                    <br/>
                                                    {
                                                        data.stats && data.stats.system && (
                                                            <>System:&nbsp;
                                                                <span className={"text-success"}>
                                                                    {data.stats.system.pass} Passing
                                                                </span>
                                                                &nbsp;&mdash;&nbsp;
                                                                <span className={"text-danger"}>
                                                                    {data.stats.system.fail} Failing
                                                                </span>
                                                            </>
                                                        )
                                                    }
                                                </li>);
                                        })
                                    }
                                </ul>
                            </div>);
                        })
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={e => setAnonymousMode(!anonymousMode)}>Toggle Anonymous Mode</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default InstructorWorkshopUI;
