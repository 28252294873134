import React, { useCallback, useEffect } from 'react';
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { auth, db } from "./services/firebase";

import Home from "./pages/Home";
import { useDispatch, useSelector } from "react-redux";
import { createRegistration, selectLoggedInAdmin, setCurrentUser, setTheme } from "./features/auth/auth-slice";
import PublicRoute from "./features/auth/PublicRoute";
import AdminRoute from "./features/auth/AdminRoute";
import AdminHome from "./pages/AdminHome";
import AdminLogin from "./features/auth/AdminLogin";
import FirestoreClient from "./pages/FirestoreClient";
import WorkshopExercise from "./features/workshops/WorkshopExercise";
import InstructorWorkshopUI from "./features/workshops/InstructorWorkshopUI";
import { NavDropdown, Toast } from "react-bootstrap";
import { clearToast, selectToast } from "./features/toast/toast-slice";
import WorkshopShow from "./features/workshops/WorkshopShow";
import PrivateRoute from "./features/auth/PrivateRoute";
import TermsOfService from "./pages/TermsOfService";
import LessonShow from "./features/lessons/LessonShow";
import LessonExercise from "./features/lessons/LessonExercise";
import StudentDashboard from "./pages/StudentDashboard";
import ManagerDashboard from "./pages/ManagerDashboard";
import JUnitResults from "./pages/JUnitResults";
import ScrollToTop from './shared/ScrollToTop';
import firebase from "firebase/compat/app";
import InstructorLessonUI from "./features/lessons/InstructorLessonUI";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function App() {
    const dispatch = useDispatch();
    const loggedInAdmin = useSelector(selectLoggedInAdmin);
    const toast = useSelector(selectToast);
    const history = useHistory();
    const query = useQuery();
    const token = localStorage.getItem("invitationToken") || query.get("t");

    const toggleDark = () => {
        // eslint-disable-next-line no-unused-expressions
        const classNames = document.body.className.split(" ");
        const isDark = classNames.includes("bootstrap-dark");
        switchTheme(!isDark, true);
    };

    const switchTheme = useCallback((isDark, storeInLocalStorage) => {
        // eslint-disable-next-line no-unused-expressions
        const classNames = document.body.className.split(" ");
        const otherClassNames = classNames.filter(name => name !== "bootstrap" && name !== "bootstrap-dark");
        const themePreference = isDark ? "bootstrap-dark" : "bootstrap";
        const newClasses = otherClassNames.concat(themePreference);
        document.body.className = newClasses.join(" ");
        if (storeInLocalStorage) {
            localStorage.setItem("crux-theme", isDark ? "dark" : "light");
        }
        dispatch(setTheme(isDark ? "light" : "dark"));
    }, [dispatch]);

    useEffect(() => {
        const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        darkModeMediaQuery.addEventListener("change", (e) => {
            const darkModeOn = e.matches;
            if (!localStorage.getItem("crux-theme")) {
                switchTheme(darkModeOn, false);
            }
        });

        const themePreference = localStorage.getItem("crux-theme") || (darkModeMediaQuery.matches ? "dark" : "light");
        document.body.classList.add(themePreference === "dark" ? "bootstrap-dark" : "bootstrap");
        dispatch(setTheme(themePreference === "dark" ? "dark" : "light"));
    }, [dispatch, switchTheme]);

    useEffect(() => {
        if (query.get("t")) {
            localStorage.setItem("invitationToken", query.get("t")!);
        }
    }, [query]);

    useEffect(() => {
        (async () => {
            let unsubscribe: (() => void) | null = null;
            let metadataRef = null;

            auth.onAuthStateChanged(async function (user: firebase.User | null) {
                console.log("onAuthStateChanged");
                if (user) {
                    console.log("onAuthStateChanged user is present");
                    const {claims} = await user.getIdTokenResult();
                    if (claims.admin) {
                        console.log("onAuthStateChanged user is admin");
                        dispatch(setCurrentUser({
                            id: user.uid,
                            email: user.email!,
                            displayName: user.displayName,
                            claims
                        }));
                    } else if (claims.clients) {
                        console.log("onAuthStateChanged user has clients");
                        dispatch(setCurrentUser({
                            id: user.uid,
                            email: user.email!,
                            displayName: user.displayName,
                            claims
                        }));

                        let redirectLocation = localStorage.getItem("redirectLocation");
                        if (redirectLocation) {
                            localStorage.removeItem("redirectLocation");
                            history.replace(JSON.parse(redirectLocation));
                        }
                    } else {
                        console.log("onAuthStateChanged user has no claims... token=", token);
                        metadataRef = db.collection('registrations').doc(user.uid);
                        unsubscribe = metadataRef.onSnapshot(async querySnapshot => {
                            if (querySnapshot.exists && querySnapshot.data()!.registeredAt) {
                                await user.getIdToken(true);
                                unsubscribe!();
                                const {claims} = await user.getIdTokenResult();
                                dispatch(setCurrentUser({
                                    id: user.uid,
                                    email: user.email!,
                                    displayName: user.displayName,
                                    claims
                                }));
                                localStorage.removeItem("invitationToken");
                                let redirectLocation = localStorage.getItem("redirectLocation");
                                if (redirectLocation) {
                                    localStorage.removeItem("redirectLocation");
                                    history.replace(JSON.parse(redirectLocation));
                                }
                            }
                        });

                        if (token) dispatch(createRegistration(user, token));
                    }
                } else {
                    console.log("onAuthStateChanged user is not present");
                    dispatch(setCurrentUser(null));
                }
            });
        })();
    }, [dispatch, history, token]);

    const logout = () => auth.signOut();

    const nav = (fluid: boolean) => {
        return (
            <Navbar className={"navbar-themed"}>
                <Container fluid={fluid}>
                    <Navbar.Brand as={Link} to="/">
                        <span>crux</span>
                    </Navbar.Brand>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <button className={"btn"} onClick={toggleDark}>
                                Switch Theme
                            </button>
                            {
                                loggedInAdmin && (
                                    <NavDropdown title="Admin" id="admin-menu">
                                        <NavDropdown.Item as={Link} to={"/admin"}>
                                            Dashboard
                                        </NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to={"/admin/firestore-client"}>
                                            Firestore Client
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                )
                            }
                            {
                                !!auth.currentUser && (
                                    <NavDropdown title={auth.currentUser.displayName || auth.currentUser.email}
                                                 id="user-menu">
                                        <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                                    </NavDropdown>
                                )
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }

    const footer = (fluid: boolean) => {
        return (
            <footer>
                <Navbar className={"navbar-themed"}>
                    <Container className={"p-3 pb-5"} fluid={fluid}>
                        <Link to={"/terms"} className={"nav-item-themed"}>Terms</Link>
                        <div className={"text-right"}>
                            <b>crux</b>
                            <br/>
                            Based in Colorado,<br/>
                            overcoming your crux everywhere.
                        </div>
                    </Container>
                </Navbar>
            </footer>
        )
    }

    return (
        <>
            <ScrollToTop/>
            {
                toast.visible && (
                    <div className="toast-container position-absolute p-3">
                        <Toast onClose={() => dispatch(clearToast())} show={toast.visible}>
                            <Toast.Header>
                                <strong className="mr-auto">{toast.toast?.header}</strong>
                                <small>just now</small>
                            </Toast.Header>
                            <Toast.Body>{toast.toast?.message}</Toast.Body>
                        </Toast>
                    </div>
                )
            }
            <div className="wrapper">
                <Switch>
                    <Route path="/workshops/:workshopId/exercises/:exerciseId">{nav(true)}</Route>
                    <Route path="/lessons/:lessonId/exercises/:exerciseId">{nav(true)}</Route>
                    <Route path="/">{nav(false)}</Route>
                </Switch>

                <Switch>
                    <PublicRoute path="/terms" component={TermsOfService}/>
                    <PublicRoute path="/admin/login" component={AdminLogin}/>
                    <AdminRoute path="/admin/junit" component={JUnitResults}/>
                    <AdminRoute path="/admin/firestore-client" component={FirestoreClient}/>
                    <AdminRoute path="/admin/workshops/:workshopId" component={InstructorWorkshopUI}/>
                    <AdminRoute path="/admin/lessons/:lessonId" component={InstructorLessonUI}/>
                    <AdminRoute path="/admin" component={AdminHome}/>

                    <PrivateRoute path="/workshops/:workshopId/exercises/:exerciseId" component={WorkshopExercise}/>
                    <PrivateRoute path="/workshops/:workshopId" component={WorkshopShow}/>

                    <PrivateRoute path="/lessons/:lessonId/exercises/:exerciseId" component={LessonExercise}/>
                    <PrivateRoute path="/lessons/:lessonId" component={LessonShow}/>

                    <PrivateRoute path="/student-dashboard" component={StudentDashboard}/>
                    <PrivateRoute path="/manager-dashboard" component={ManagerDashboard}/>

                    <Route path="/login" component={Home}/>
                    <Route path="/" component={Home}/>
                </Switch>
            </div>

            <Switch>
                <Route path="/workshops/:workshopId/exercises/:exerciseId"/>
                <Route path="/lessons/:lessonId/exercises/:exerciseId"/>
                <Route path="/">{footer(false)}</Route>
            </Switch>
        </>
    );
}

export default App;
