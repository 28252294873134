import { Redirect, Route } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { selectAuthLoading, selectLoggedIn } from "./auth-slice";

type RouteProps = any;
function PrivateRoute({ component: Component, ...rest }: RouteProps) {
    const authenticated = useSelector(selectLoggedIn);
    const authLoading = useSelector(selectAuthLoading);

    if (authLoading) return <div/>;

    return (
        <Route
            {...rest}
            render={(props) => {
                if (authenticated) {
                    return <Component {...props} />;
                } else {
                    localStorage.setItem("redirectLocation", JSON.stringify(props.location));
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>;
                }
            }}
        />
    );
}

export default PrivateRoute;
