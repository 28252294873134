import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { db, Timestamp } from "../services/firebase";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { DateTime } from "luxon";

function JUnitResults() {

    const dispatch = useDispatch();
    const [submissions, setSubmissions] = useState([]);
    const [date, setDate] = useState(DateTime.now());

    useEffect(() => {
        return db.collection("junit-submissions")
                 .where("createdAt", ">=", Timestamp.fromDate(date.startOf('day').toJSDate()))
                 .orderBy("createdAt")
                 .onSnapshot(snapshot => {
                     const docs = [];
                     snapshot.forEach(doc => docs.push({ id: doc.id, ...doc.data() }));
                     setSubmissions(docs);
                 });
    }, [date, dispatch]);

    const summary = submissions.reduce((hash, submission) => {
        submission.results.forEach(result => {
            if (result.testIdentifier.test) {
                let testId = `${result.testIdentifier.source.className}.${result.testIdentifier.displayName}`;
                hash.testIds.add(testId);
                hash.users.add(submission.username);
                hash.data[submission.username] = hash.data[submission.username] || {};
                hash.data[submission.username][testId] = result.testExecutionResult.status;
            }
        });

        return hash;
    }, { data: {}, users: new Set(), testIds: new Set() });

    const table = [];
    const testIds = [...summary.testIds].sort();
    const users = [...summary.users].sort();
    users.forEach(user => {
        const row = [user];
        testIds.forEach(id => row.push((summary.data[user] || {})[id]));
        table.push(row);
    });

    return (
        <Container className={"mt-4"} fluid>
            <Row>
                <Col>
                    Start Date:
                    <input type="date"
                           value={date.toISODate()}
                           onChange={e => setDate(DateTime.fromISO(e.target.value))}
                           className={"form-control"}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <table className={"table table-sm table-striped table-bordered"}>
                        <thead>
                        <tr>
                            <th>User</th>
                            {
                                testIds.map(id => <th className={"text-center"} key={id}>{id}</th>)
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            table.map((row, i) => (
                                <tr key={i}>
                                    {
                                        row.map((value, j) => {
                                            const className = {
                                                "SUCCESSFUL": "text-center",
                                                "FAILED": "text-center"
                                            }[value];
                                            const icon = {
                                                "SUCCESSFUL": <FontAwesomeIcon icon={faCheck} color={"green"}/>,
                                                "FAILED": <FontAwesomeIcon icon={faTimes} color={"red"}/>
                                            }[value] || value;
                                            return (
                                                <td key={j} className={className}>
                                                    {icon}
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </Col>
            </Row>
        </Container>
    );
}

export default JUnitResults;
