import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { enrollmentContext, lessonContext, withEnrollment, withLesson } from "../../components/contexts";
import Markdown from "../../shared/Markdown";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";

function LessonShow() {
    const { lesson: lessonDoc } = useContext(lessonContext);
    const { enrollment: enrollmentDoc } = useContext(enrollmentContext);
    const lesson = lessonDoc!;
    const enrollment = enrollmentDoc!;

    const currentAttempt = (enrollment.data.attempts || {})[enrollment.data.currentAttempt] || {};

    return (
        <Container className={"mt-5"}>
            <Row>
                <Col xs="12">
                    <h2 className={"mb-3"}>{lesson.data.name}</h2>
                    {
                        lesson.data.exercises.map(exercise => {
                            const status = currentAttempt?.exercises[exercise.id]?.status;
                            const complete = status === "complete";
                            const inProgress = status === "in-progress";
                            const borderClass = complete ? "border-success" : (inProgress ? "border-warning" : "");
                            return (
                                <Row key={exercise.id} className={`border p-3 mb-3 ${borderClass}`}>
                                    <Col xs={9}>
                                        {
                                            exercise.data.type === "multiple-choice" && (
                                                <FontAwesomeIcon icon={faQuestionCircle}
                                                                 className={"d-inline-block mr-2"}/>
                                            )
                                        }
                                        {
                                            exercise.data.type !== "multiple-choice" && (
                                                <FontAwesomeIcon icon={faCode} className={"d-inline-block mr-2"}/>
                                            )
                                        }

                                        <Link to={`/lessons/${lesson.id}/exercises/${exercise.id}`}>
                                            {exercise.data.name}
                                        </Link>

                                        {
                                            currentAttempt?.exercises[exercise.id]?.status === "complete" && (
                                                <FontAwesomeIcon icon={faCheck}
                                                                 className={"d-inline-block ml-2 text-success"}/>
                                            )
                                        }
                                    </Col>
                                    <Col xs={3} className={"text-right"}>
                                        <Link to={`/lessons/${lesson.id}/exercises/${exercise.id}`}
                                              className={"btn btn-info"}>Go</Link>
                                    </Col>
                                </Row>
                            );
                        })
                    }
                </Col>
                <Col xs="12">
                    <Markdown markdown={lesson.data.description}/>

                    <Markdown markdown={lesson.data.cheatSheet}/>
                </Col>
            </Row>

        </Container>
    );
}

export default withLesson(withEnrollment(LessonShow));
