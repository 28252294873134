import React, { useContext, useEffect, useState } from "react";
import sdk from "@stackblitz/sdk";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { functions } from "../../services/firebase";
import { createSubmission } from "../students/student-api";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/auth-slice";
import TestResultList from "./TestResultList";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import MarkdownDropdown from "./MarkdownDropdown";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavItem } from "react-bootstrap";
import { enrollmentContext, lessonContext, workshopContext } from "../../components/contexts";
import { Exercise, Submission, Document } from "../../../../types";

type StackblitzExerciseProps = {
    exercise: Document<Exercise>,
    enrollmentExercise: any,
    breadcrumb: JSX.Element,
    previousLink: JSX.Element,
    nextLink: JSX.Element
}
function StackblitzExercise({ exercise, enrollmentExercise, breadcrumb, previousLink, nextLink }: StackblitzExerciseProps) {
    const params = useParams<{workshopId?: string}>();

    const { lesson } = useContext(lessonContext);
    const { enrollment } = useContext(enrollmentContext);
    const { workshop } = useContext(workshopContext);
    const currentUser = useSelector(selectCurrentUser);

    const [vm, setVm] = useState<any>(null);
    const [disableOnSubmit, setDisableOnSubmit] = useState(false);
    const [apiResponse, setApiResponse] = useState<any>(null);
    const [cheatSheetVisible, setCheatSheetVisible] = useState(false);
    const [showDescription, setShowDescription] = useState(true);

    useEffect(() => {
        (async () => {
            const { files: enrollmentFiles } = enrollmentExercise.latestSubmission || {};
            const projectVm = await sdk.embedProject(
                "stackblitz-container",
                {
                    ...exercise.data.stackblitz.project,
                    files: enrollmentFiles || exercise.data.stackblitz.project.files
                },
                {
                    // height: '800px',
                    width: "100%",
                    hideExplorer: false,
                    hideNavigation: true,
                    forceEmbedLayout: true,
                    ...(exercise.data.stackblitz.options || {})
                }
            );

            setVm(projectVm);
            window.onbeforeunload = (e: BeforeUnloadEvent) => {
                const el = document.getElementById('stackblitz-container');
                el?.parentNode?.removeChild(el);
                window.onbeforeunload = null;
            };
        })();
    }, [exercise, enrollment, enrollmentExercise.latestSubmission]);

    const onSubmit = async () => {
        const files = await vm.getFsSnapshot();
        setDisableOnSubmit(true);
        setApiResponse(null);

        const submitCode = functions.httpsCallable('submitCode');
        try {
            const testResults = (await submitCode({ files, exerciseId: exercise.id })).data;
            if (testResults.system && testResults.system.stats) {
                setApiResponse(testResults);
                const data: Submission = {
                    user: currentUser!,
                    lessonId: lesson!.id,
                    language: 'stackblitz',
                    exerciseId: exercise.id,
                    exerciseType: exercise.data.type,
                    currentAttempt: enrollment!.data.currentAttempt,
                    files,
                    testResults
                };
                if (params.workshopId && workshop) {
                    data.clientId = workshop.data.client.id;
                    data.workshopId = workshop.id;
                }
                await createSubmission(data);
            }
        } catch (e) {
            console.log(e);
            alert(e.message);
        }
        setDisableOnSubmit(false);
    };

    return (
        <div>
            <Navbar className={"navbar-themed pb-2 pt-2"}>
                <Container fluid>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {breadcrumb}

                        <Nav className="ml-auto">
                            <Button
                                onClick={e => setShowDescription(!showDescription)}
                                variant="link"
                                className="mr-4 btn-sm btn-no-outline">
                                <FontAwesomeIcon color={"#ccc"}
                                                 className={"d-inline-block mr-2"}
                                                 icon={showDescription ? faAngleDown : faAngleRight}/>
                                {showDescription ? "Hide" : "Show"} Description
                            </Button>
                            <Button
                                onClick={e => setCheatSheetVisible(!cheatSheetVisible)}
                                variant="link"
                                className="mr-4 btn-sm btn-no-outline">
                                <FontAwesomeIcon color={"#ccc"}
                                                 className={"d-inline-block mr-2"}
                                                 icon={cheatSheetVisible ? faAngleDown : faAngleRight}/>
                                {cheatSheetVisible ? "Hide" : "Show"} Cheat Sheet
                            </Button>
                            <Button size={"sm"}
                                    onClick={onSubmit}
                                    className={"d-inline-block mr-2"}
                                    disabled={disableOnSubmit}>
                                Submit
                            </Button>
                            <NavItem>{previousLink}</NavItem>
                            <NavItem>{nextLink}</NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container fluid>
                {showDescription && (
                    <Container fluid>
                        <MarkdownDropdown markdown={exercise.data.description} setVisible={setShowDescription}
                                          title={exercise.data.name}/>
                    </Container>
                )}

                {cheatSheetVisible && (
                    <Container fluid>
                        <MarkdownDropdown markdown={lesson!.data.cheatSheet} setVisible={setCheatSheetVisible}/>
                    </Container>
                )}
            </Container>

            <div id={"stackblitz-container"} className={"vh-100"}>
            </div>

            <Container fluid>
                <Row>
                    <Col>
                        {
                            apiResponse && apiResponse.system && (
                                <>
                                    <h3>System Tests</h3>
                                    <TestResultList data={apiResponse.system}/>
                                </>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default React.memo(
    StackblitzExercise,
    (prevProps, nextProps) => {
        return JSON.stringify(prevProps.exercise) === JSON.stringify(nextProps.exercise);
    }
);
