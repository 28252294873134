import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import {
    enrollmentContext,
    lessonContext, withAttendance,
    withEnrollment,
    withWorkshop,
    workshopContext
} from "../../components/contexts";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Markdown from "../../shared/Markdown";
import CFUModal from "../exercises/CFUModal";
import { selectCurrentUser } from "../auth/auth-slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { watchDeliveredExercises } from "./workshop-api";
import { ExerciseAttempt } from "../../../../types";

function WorkshopShow() {
    const {workshop} = useContext(workshopContext);
    const {lesson} = useContext(lessonContext);
    const {enrollment} = useContext(enrollmentContext);
    const currentUser = useSelector(selectCurrentUser);

    const [lastCfuId, setLastCfuId] = useState<string | null>(null);
    const [deliveredCfus, setDeliveredCfus] = useState<string[]>([]);

    useEffect(
        () => watchDeliveredExercises(workshop!, currentUser!, setDeliveredCfus),
        [workshop, currentUser]
    );

    const currentAttempt = enrollment!.data.attempts[enrollment!.data.currentAttempt];
    const answeredCfus: {[key: string]: ExerciseAttempt} = {};
    for (const [id, response] of Object.entries(currentAttempt.exercises) as any) {
        if (response.type === "multiple-choice") {
            answeredCfus[id] = response;
        }
    }

    const firstUnansweredCfuId = deliveredCfus.find(id => !answeredCfus[id]);
    const firstUnansweredCfu = lesson!.data.exercises.find(exercise => exercise.id === firstUnansweredCfuId);
    const lastCfu = lesson!.data.exercises.find(exercise => exercise.id === lastCfuId);
    console.log("lastCfuId", lastCfuId);
    console.log("lastCfu", lastCfu);
    console.log("firstUnansweredCfu", firstUnansweredCfu);
    const displayCfu = firstUnansweredCfu || lastCfu;
    const codeExercises = lesson!.data.exercises.filter(exercise => exercise.data.type !== "multiple-choice");

    return (
        <>
            {
                displayCfu && (
                    <CFUModal exercise={displayCfu}
                              setLastCfuId={setLastCfuId}/>
                )
            }
            <Container className={"mt-5"}>
                <Row>
                    <Col>
                        <h2 className={"mb-3"}>{lesson!.data.name}</h2>
                    </Col>
                </Row>
                <Row>
                    {
                        !!codeExercises.length && (
                            <Col xs="12">
                                {
                                    codeExercises.map(exercise => {
                                        const status = currentAttempt?.exercises[exercise.id]?.status;
                                        const complete = status === "complete";
                                        const inProgress = status === "in-progress";
                                        const borderClass = complete ? "border-success" : (inProgress ? "border-warning" : "")
                                        return (
                                            <Row key={exercise.id} className={`border p-3 mb-3 ${borderClass}`}>
                                                <Col xs={9}>
                                                    {
                                                        exercise.data.type === "multiple-choice" && (
                                                            <FontAwesomeIcon icon={faQuestionCircle}
                                                                             className={"d-inline-block mr-2"}/>
                                                        )
                                                    }
                                                    {
                                                        exercise.data.type !== "multiple-choice" && (
                                                            <FontAwesomeIcon icon={faCode}
                                                                             className={"d-inline-block mr-2"}/>
                                                        )
                                                    }
                                                    {exercise.data.name}
                                                    {
                                                        currentAttempt?.exercises[exercise.id]?.status === "complete" && (
                                                            <FontAwesomeIcon icon={faCheck}
                                                                             className={"d-inline-block ml-2 text-success"}/>
                                                        )
                                                    }
                                                </Col>
                                                <Col xs={3} className={"text-right"}>
                                                    <Link to={`/workshops/${workshop!.id}/exercises/${exercise.id}`}
                                                          className={"btn btn-info"}>Go</Link>
                                                </Col>
                                            </Row>
                                        );
                                    })
                                }
                            </Col>
                        )
                    }
                    <Col xs="12">
                        <Markdown markdown={workshop!.data.summary}/>
                        <Markdown markdown={lesson!.data.description}/>
                        <Markdown markdown={lesson!.data.cheatSheet}/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default withWorkshop(withEnrollment(withAttendance(WorkshopShow)));
