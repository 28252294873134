import React, { FormEvent, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { auth, db, githubProvider } from "../../services/firebase";
import Button from "react-bootstrap/Button";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

function Login() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const token = new URLSearchParams(useLocation().search).get("t");

    useEffect(() => {
        if (!token) return;
        (async () => {
            const doc = await db.collection("client-invitation-tokens").doc(token).get();
            if (!doc.exists) {
                setError(true);
            }
        })();
    }, [history, token, dispatch, setError]);

    if (error) return null;

    const login = async (e: FormEvent) => {
        e.preventDefault();
        await auth.signInWithRedirect(githubProvider);
    };

    return (
        <Row className={"mt-2"}>
            <Col md={{ span: 6, offset: 3 }}>
                <form onSubmit={login}>
                    <h3 className={"mb-4"}>Login</h3>
                    <Button type={"submit"}>Login with Github</Button>
                </form>
            </Col>
        </Row>
    );
}

export default Login;
