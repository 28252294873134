import firebase from "firebase/compat";
import { DeliveredExercise } from "../../../../types";

export const deliveredExerciseConverter = {
    toFirestore(object: DeliveredExercise) {
        return object;
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): DeliveredExercise {
        return snapshot.data(options) as DeliveredExercise;
    }
};
