import React, { FormEvent, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Editor, { useMonaco } from "@monaco-editor/react";
import Button from "react-bootstrap/Button";
import { db } from "../services/firebase";
import Container from "react-bootstrap/Container";

const FirestoreClient = () => {
    const [collection, setCollection] = useState("");
    const [id, setId] = useState("");
    const [json, setJson] = useState("");
    const [error, setError] = useState("");
    const [strategy, setStrategy] = useState("set");

    const templateTypes = [
        "enrollments",
        "exercises",
        "lessons",
        "submissions",
        "workshop-instructor-summaries",
        "workshops",
    ];

    const monaco = useMonaco();

    useEffect(() => {
        if (monaco) {
            monaco.editor.setTheme('vs-dark');
        }
    }, [monaco]);

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();

        try {
            if (strategy === "set" || strategy === "update") {
                if (window.confirm(`Are you sure you want to update ${collection}??`)) {
                    await db.collection(collection).doc(id)[strategy](JSON.parse(json));
                    setError("");
                }
            } else {
                const snapshot = await db.collection(collection).doc(id).get();
                if (snapshot.exists) {
                    setJson(JSON.stringify(snapshot.data(), null, 2));
                    setError("");
                } else {
                    setError("Not found");
                }
            }
        } catch (e) {
            setError(e.toString());
        }
    }

    return <Container fluid>
        <form onSubmit={handleSubmit}>
            {error && (
                <Row>
                    <Col>
                        <div className="alert alert-danger">
                            {error}
                        </div>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <label>Collection</label>
                    <div>
                        <select className="form-control"
                                value={collection}
                                onChange={e => {
                                    setCollection(e.target.value);
                                }}>
                            <option>(select one)</option>
                            {templateTypes.map(templateType => (
                                <option value={templateType} key={templateType}>{templateType}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col>
                    <label>ID</label>
                    <div>
                        <input type="text"
                               className="form-control"
                               value={id}
                               onChange={e => setId(e.target.value)}/>
                    </div>
                </Col>
                <Col>
                    <label>Strategy</label>
                    <div>
                        <select className="form-control"
                                value={strategy}
                                onChange={e => {
                                    setStrategy(e.target.value);
                                }}>
                            <option value="set">Set</option>
                            <option value="update">Update</option>
                            <option value="get">Get</option>
                        </select>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <label>JSON</label>
                    <div>
                        <Editor
                            height="30vh"
                            defaultLanguage="json"
                            value={json}
                            onChange={value => setJson(String(value))}
                            options={{
                                minimap: {
                                    enabled: false,
                                },
                                fontSize: 12,
                                scrollBeyondLastLine: false,
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button disabled={!id || !collection || !json} type="submit">
                        Commit
                    </Button>
                </Col>
            </Row>
        </form>
    </Container>;
};

export default FirestoreClient;
