import React from "react";
import Container from "react-bootstrap/Container";

function TermsOfService() {
    return (
        <Container>
            <iframe allowTransparency
                    title={"Terms of Service"}
                    style={{ width: "100%", height: "80vh", background: "white" }}
                    src="/terms.html"
                    frameBorder="0"/>
        </Container>
    );
}

export default TermsOfService;
