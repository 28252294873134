import React, {useContext, useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import {lessonContext, withLesson} from "../../components/contexts";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import {fetchEnrollmentsForLesson} from "../students/student-api";
import {Document, Enrollment, Exercise} from "../../../../types";
import Table from "react-bootstrap/Table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function InstructorLessonUI() {
    const {lesson: lessonDoc} = useContext(lessonContext);
    const [enrollments, setEnrollments] = useState<Document<Enrollment>[]>([])
    const lesson = lessonDoc!;

    useEffect(() => {
        (async () => {
            setEnrollments(await fetchEnrollmentsForLesson(lesson.id))
        })();
    }, [lesson.id])

    function getResult(enrollment: Document<Enrollment>, exercise: Document<Exercise>): { correct: boolean, answer?: string } | undefined {
        const attempt = enrollment.data.attempts[enrollment.data.currentAttempt];
        if (!attempt) return undefined;

        let exerciseAttempt = attempt.exercises[exercise.id];
        if (!exerciseAttempt) return undefined;

        switch (exerciseAttempt.type) {
            case "multiple-choice":
                if (exerciseAttempt.correct) {
                    return {
                        correct: true
                    }
                } else {
                    return {
                        correct: false,
                        answer: exerciseAttempt.answer
                    }
                }
            case "piston":
                const submissionCount = Object.keys(exerciseAttempt.submissions).length
                return {
                    correct: exerciseAttempt.status === "complete",
                    answer: `${submissionCount} submission${submissionCount === 1 ? "" : "s"}`
                }
            case "code-with-tests":
                break;
            case "tdd":
                break;
            case "stackblitz":
                break;
        }
    }

    const displayName = (enrollment: Document<Enrollment>) => {
        return enrollment.data.user.displayName || enrollment.data.user.email.split("@")[0]
    }

    return (
        <Container fluid className={"mt-4"}>
            <Row>
                <Col>
                    <h2 className={"mb-4 border-bottom pb-2"}>{lesson.data.name}</h2>
                    <Table bordered size={"sm"}>
                        <thead>
                        <tr>
                            <th/>
                            {lesson.data.exercises.map(e => e.data.name).map((cell, i) => (
                                <th key={i} className={"small align-middle text-center"}>{cell}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            enrollments.map(enrollment => {
                                return (
                                    <tr key={enrollment.id}>
                                        <th className={"small align-middle"}>{displayName(enrollment)}</th>
                                        {
                                            lesson.data.exercises.map(exercise => getResult(enrollment, exercise)).map((cell, i) => {
                                                let content: JSX.Element = <></>;
                                                if (cell?.correct) {
                                                    content = <FontAwesomeIcon icon={faCheck}
                                                                               className={"text-success"}/>
                                                }
                                                else if (cell && !cell.correct) {
                                                    content = <div>
                                                        <FontAwesomeIcon icon={faTimes} className={"text-danger"}/>
                                                        {cell.answer ? <div className={"text-secondary"}>({cell.answer})</div> : ""}
                                                    </div>
                                                }

                                                return (
                                                    <td key={i} className={"text-center small align-middle"}>
                                                        {content}
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default withLesson(InstructorLessonUI);
