import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { submitAnswer } from "../students/student-api";
import { useSelector } from "react-redux";
import MultipleChoice from "./MultipleChoice";
import { selectCurrentUser } from "../auth/auth-slice";
import { enrollmentContext, workshopContext } from "../../components/contexts";
import { Document, Exercise, MultipleChoiceExercise } from "../../../../types";

type CFUModalProps = { exercise: Document<Exercise>, setLastCfuId: (x: string | null) => void };
const CFUModal = ({exercise, setLastCfuId}: CFUModalProps) => {
    const {workshop} = useContext(workshopContext);
    const {enrollment, reloadEnrollment} = useContext(enrollmentContext);
    const [showCloseButton, setShowCloseButton] = useState(false);
    const currentUser = useSelector(selectCurrentUser);

    const onAnswered = async (currentResponse: string) => {
        setShowCloseButton(true);
        await submitAnswer(
            workshop!,
            enrollment!,
            currentUser!,
            exercise as Document<MultipleChoiceExercise>,
            currentResponse
        );
        setLastCfuId(exercise.id);
    };

    return (
        <Modal
            show={true}
            onHide={async () => {
                setLastCfuId(null);
                await reloadEnrollment();
            }}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
            centered
        >
            <Modal.Header closeButton={showCloseButton}>
                Check your understanding:
            </Modal.Header>

            <Modal.Body>
                {
                    !!exercise && (
                        <MultipleChoice
                            exercise={exercise as Document<MultipleChoiceExercise>}
                            onAnswered={onAnswered}
                            answeredAlready={false}/>
                    )
                }
            </Modal.Body>
        </Modal>
    );
};

export default CFUModal;
