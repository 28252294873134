import React, { FormEvent, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { createClient } from "./client-api";

function ClientForm({afterCreate}: {afterCreate: () => void}) {
    const [name, setName] = useState("");
    const [id, setId] = useState("");

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        await createClient({ id, name });
        setName("");
        setId("");
        afterCreate();
    };

    return (
        <Row className={"mb-5"}>
            <Col md={6}>
                <form onSubmit={onSubmit}>
                    <div className={"mb-4"}>
                        <label htmlFor="date">ID (abbreviation)</label>
                        <div>
                            <input type="text"
                                   className="form-control"
                                   value={id}
                                   onChange={e => setId(e.target.value)}/>
                        </div>
                    </div>
                    <div className={"mb-4"}>
                        <label htmlFor="date">Name</label>
                        <div>
                            <input type="text"
                                   className="form-control"
                                   value={name}
                                   onChange={e => setName(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <button disabled={!name || !id}
                                type={"submit"}
                                className={"btn btn-primary"}>
                            Create Client
                        </button>
                    </div>
                </form>
            </Col>
        </Row>
    );
}

export default ClientForm;
