import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import Markdown from "../../shared/Markdown";
import React from "react";

type SectionProps = {section: any, testResult: any, submitting: boolean, hasStdErr: boolean, id: string};
export default function Section({section, testResult, submitting, hasStdErr, id}: SectionProps) {
    const passed = testResult?.result === "passed";
    const failed = testResult?.result === "failed";

    return (
        <Card className={"mb-3"}
              id={id}
              border={(failed || hasStdErr) ? "danger" : (passed ? "success" : undefined)}>
            <Card.Header className={passed ? "bg-success text-white" : (failed ? "bg-danger text-white" : undefined)}>
                {
                    submitting && <Spinner animation={"border"} size={"sm"} className={"float-right"}/>
                }
                {
                    !submitting && failed && <FontAwesomeIcon icon={faTimes} className={"float-right"}/>
                }
                {
                    !submitting && passed && <FontAwesomeIcon icon={faCheck} className={"float-right"}/>
                }
                <strong>Challenge #{section.number}</strong>
            </Card.Header>
            <Card.Body>
                <Markdown markdown={section.text}/>
                {
                    failed && !!testResult.message && (
                        <div>
                            <p><strong>Error Message</strong></p>
                            <pre><code>{testResult.message}</code></pre>
                        </div>
                    )
                }
                {
                    !!testResult?.output && (
                        <div>
                            <p><strong>Your Console Output</strong></p>
                            <pre><code>{testResult.output}</code></pre>
                        </div>
                    )
                }
            </Card.Body>

        </Card>
    )
}
