import { DateTime } from "luxon";
import { Attendance, Enrollment } from "../../../../types";
import firebase from "firebase/compat";

export const attendanceConverter = {
    toFirestore(attendance: Attendance) {
        return attendance;
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): Attendance {
        const data = snapshot.data(options) as Attendance;
        Object.keys(data.exercises).forEach(exerciseId => {
            const createdAt = data.exercises[exerciseId].stats.createdAt;
            data.exercises[exerciseId].stats.createdAt = DateTime.fromJSDate(createdAt.toDate()).toISO();
        });
        return data;
    }
};

export const enrollmentConverter = {
    toFirestore(enrollment: Enrollment) {
        return enrollment;
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): Enrollment {
        const data = snapshot.data(options) as Enrollment;
        Object.values((data.attempts[data.currentAttempt] || {exercises: {submissions: {}}}).exercises).forEach((exercise: any) => {
            Object.values(exercise.submissions || {}).forEach((submission: any) => {
                submission.createdAt = submission.createdAt ? DateTime.fromJSDate(submission.createdAt.toDate()).toISO() : undefined;
            });
        });
        return data;
    }
};
