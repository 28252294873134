import React from "react";
import marked from "marked";

const renderer = new marked.Renderer();
renderer.paragraph = text => text;

const options = {renderer};

const SingleLineMarkdown = ({markdown}: { markdown: string }) => {
    const __html = marked(markdown, options);
    return <span className={"markdown single-line"} dangerouslySetInnerHTML={{__html}}/>;
};

export default SingleLineMarkdown;
