import React from "react";
import { Client, Document } from "../../../../types";

function ClientRow({client}: { client: Document<Client> }) {
    const {id, data: {name, invitationTokens}} = client;
    return (
        <tr>
            <td>{id}</td>
            <td>{name}</td>
            <td>
                {
                    invitationTokens && Object.keys(invitationTokens).length && (
                        <ul className={"list-unstyled"}>
                            {Object.keys(invitationTokens).map(token => (
                                <li key={token}>{token}</li>
                            ))}
                        </ul>
                    )
                }
            </td>
        </tr>
    );
}

function ClientList({clients}: { clients: Document<Client>[] }) {

    if (!clients.length) return (
        <div>No clients to display</div>
    );

    return (
        <table className="table table-sm table-striped table-bordered">
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Invitation Tokens</th>
            </tr>
            </thead>
            <tbody>
            {clients.map(client => <ClientRow key={client.id} client={client}/>)}
            </tbody>
        </table>
    );
}

export default ClientList;
