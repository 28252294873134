import React, { useEffect, useState } from "react";
import WorkshopList from "../features/workshops/WorkshopList";
import ClientForm from "../features/clients/ClientForm";
import ClientList from "../features/clients/ClientList";
import Container from "react-bootstrap/Container";
import { fetchClients } from "../features/clients/client-api";
import { Client, Document } from "../../../types";
import LessonList from "../features/lessons/LessonList";

const AdminHome = () => {
    const [clients, setClients] = useState<Document<Client>[]>([]);

    const loadClients = async () => setClients(await fetchClients());

    useEffect(() => {
        loadClients()
    }, []);

    return (
        <Container fluid className={"mt-4"}>
            <div>
                <h3>Workshops</h3>
                <WorkshopList/>
            </div>
            <div>
                <h3>Lessons</h3>
                <LessonList/>
            </div>
            <div>
                <h3>Clients</h3>
                <ClientForm afterCreate={loadClients}/>
            </div>
            <div>
                <ClientList clients={clients}/>
            </div>
        </Container>
    );
};

export default AdminHome;
