import { createSlice } from '@reduxjs/toolkit';
import { RootState } from "../../app/store";

type ToastState = {
    toast: null | {header: string, message: string},
    visible: boolean
}

const initialState: ToastState = {
    toast: null,
    visible: false
};

export const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        setToast: (state, action) => {
            state.toast = { header: action.payload.header, message: action.payload.message };
            state.visible = true;
        },
        clearToast: (state) => {
            state.toast = null;
            state.visible = false;
        },
    },
});

export const { setToast, clearToast } = toastSlice.actions;

export const selectToast = (state: RootState) => state.toast;

export default toastSlice.reducer;
