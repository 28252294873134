import { useSelector } from "react-redux";
import React, { useContext } from "react";
import TestRunner from "./TestRunner";
import { createSubmission } from "../students/student-api";
import { selectCurrentUser } from "../auth/auth-slice";
import { useParams } from "react-router-dom";
import { Document, Exercise, Submission } from "../../../../types";
import { enrollmentContext, lessonContext, workshopContext } from "../../components/contexts";

type CodeWithTestsExerciseProps = {
    exercise: Document<Exercise>,
    enrollmentExercise: any,
    previousUrl?: string | undefined,
    nextUrl?: string | undefined
}

function CodeWithTestsExercise({exercise, enrollmentExercise, previousUrl, nextUrl}: CodeWithTestsExerciseProps) {
    console.log("rendering CodeWithTestsExercise", exercise.id);
    const params = useParams<{ workshopId?: string }>();
    const {lesson} = useContext(lessonContext);
    const {enrollment} = useContext(enrollmentContext);
    const {workshop} = useContext(workshopContext);
    const currentUser = useSelector(selectCurrentUser)!;

    const {code: enrollmentCode, tests: enrollmentTests} = enrollmentExercise.latestSubmission || {};
    const starterCode = enrollmentCode || exercise!.data.starterCode;
    const starterTests = enrollmentTests || exercise!.data.starterTests;

    const submitTestResults = (codeToSubmit: string, testsToSubmit: string, testResults: any) => {
        if (codeToSubmit || testsToSubmit) {
            const data: Submission = {
                user: currentUser,
                lessonId: lesson!.id,
                exerciseId: exercise.id,
                exerciseType: exercise.data.type,
                language: "javascript",
                currentAttempt: enrollment!.data.currentAttempt,
                code: codeToSubmit || starterCode,
                tests: testsToSubmit || starterTests,
                testResults
            };
            if (workshop && params.workshopId) {
                data.clientId = workshop.data.client.id;
                data.workshopId = workshop.id;
            }
            return createSubmission(data);
        }
    };

    return (
        <TestRunner code={starterCode}
                    tests={starterTests}
                    previousUrl={previousUrl!}
                    nextUrl={nextUrl!}
                    exercise={exercise}
                    onSubmit={submitTestResults}
        />
    );
}

export default CodeWithTestsExercise;
