import { Client, Document } from "../../../../types";
import { db, FieldValue } from "../../services/firebase";
import { clientConverter } from "./converters";

export const fetchClients = async (): Promise<Document<Client>[]> => {
    const snapshot = await db.collection("clients").withConverter(clientConverter).get();
    const clients: Document<Client>[] = [];
    snapshot.forEach(doc => clients.push({id: doc.id, data: doc.data()}));
    return clients;
};

export const createClient = async ({ id, name }: {id: string, name: string}): Promise<void> => {
    const clientRef = db.collection("clients").doc(id);
    const invitationTokenRef = db.collection("client-invitation-tokens").doc();

    await db.runTransaction(async transaction => {
        const doc = await transaction.get(clientRef);
        if (doc.exists) throw new Error("Client already exists");

        await transaction.set(invitationTokenRef, {
            createdAt: FieldValue.serverTimestamp()
        });

        await transaction.set(clientRef, {
            name,
            invitationTokens: {},
            users: {},
            createdAt: FieldValue.serverTimestamp()
        });

        await new Promise(resolve => setTimeout(resolve, 300));

        await transaction.update(clientRef, {
            [`invitationTokens.${invitationTokenRef.id}`]: true,
        });
    });
};
