import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthLoading, selectLoggedIn } from "./auth-slice";

type RouteProps = any;
function PublicRoute({ component: Component, ...rest }: RouteProps) {
    const authenticated = useSelector(selectLoggedIn);
    const authLoading = useSelector(selectAuthLoading);

    if (authLoading) return <div/>;

    return (
        <Route
            {...rest}
            render={(props) => authenticated
                ? <Redirect to='/'/>
                : <Component {...props} />
            }
        />
    );
}

export default PublicRoute;
