import React from "react";
import marked from "marked";
import prismjs from "prismjs";
import "prismjs/components/prism-typescript"
import "prismjs/components/prism-java"
// @ts-ignore
import { cleanUrl } from "marked/src/helpers";
import { useSelector } from "react-redux";
import { selectTheme } from "../features/auth/auth-slice";

const renderer = new marked.Renderer();

renderer.image = function (href, title, text) {
    if (href === null) return "";

    let classes = "";
    if (title?.includes("classes:")) {
        [title, classes] = title.split("classes:").map(string => string.trim());
    }
    if (href.startsWith("img/")) {
        href = [
            "https://firebasestorage.googleapis.com/v0/b/",
            "upslope-crux.appspot.com",
            "/o/",
            encodeURIComponent(`content/${process.env.REACT_APP_IMAGES_FOLDER}/${href.replace("img/", "")}`),
            "?alt=media",
        ].join("")
    } else {
        href = cleanUrl(this.options.sanitize, this.options.baseUrl, href);
    }
    if (href === null) {
        return text;
    }

    let out = '<img src="' + href + '" alt="' + text + '"';
    if (title) {
        out += ' title="' + title + '"';
    }
    if (classes) {
        out += ' class="' + classes + '"';
    }
    out += this.options.xhtml ? '/>' : '>';
    return out;
};

const options = {
    renderer,
    highlight: function (code: string, lang: string) {
        try {
            return prismjs.highlight(code, prismjs.languages[lang], lang);
        } catch {
            return code;
        }
    }
};

const Markdown = ({markdown}: { markdown: string | undefined | null }) => {
    const theme = useSelector(selectTheme);

    renderer.code = function (code, lang, escaped) {
        if (lang === "plantuml") {
            if (theme === "dark") {
                code = code.replace("@startuml", `@startuml\nskinparam monochrome reverse\n`)
                code = code.replace("@startwbs", `@startwbs\nskinparam monochrome reverse\n`)
            }
            const encoded = Buffer.from(code, 'utf8').toString('hex');
            return `<div class="diagram p-3 mb-4 rounded"><img src="https://www.plantuml.com/plantuml/svg/~h${encoded}" alt="Diagram" /></div>`;
        }
        code = this.options.highlight ? this.options.highlight(code, lang || "") || code : code;
        if (!lang) {
            return `<pre><code>${code}</code></pre>`;
        }

        const langClass = "language-" + lang;
        return `<pre class="${langClass}"><code class="${langClass}">${code}</code></pre>`;
    };

    let __html = marked(markdown || "", options).replace(/<table>/, `<table class="table table-sm table-striped">`);
    return <div className={"markdown"} dangerouslySetInnerHTML={{__html}}/>;
};

export default Markdown;
