import React, { useContext, useEffect, useRef, useState } from "react";
import Markdown from "../../shared/Markdown";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Editor from "@monaco-editor/react";
import { selectCurrentUser, selectTheme } from "../auth/auth-slice";
import { useSelector } from "react-redux";
import { functions } from "../../services/firebase";
import Spinner from "react-bootstrap/Spinner";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { createSubmission } from "../students/student-api";
import { Badge, Tab, Tabs } from "react-bootstrap";
import Section from "./Section";
import { enrollmentContext, lessonContext, workshopContext } from "../../components/contexts";
import { Document, Exercise } from "../../../../types";

const navBarHeight = "56px";
const footerHeight = "56px";
const runBarHeight = "56px";
const tabHeight = "56px";
const paddingHeight = "0.5rem";

type PistonExerciseProps = {
    exercise: Document<Exercise>,
    enrollmentExercise: any,
    previousUrl: string,
    nextUrl: string
}

function PistonExercise({exercise, enrollmentExercise, previousUrl, nextUrl}: PistonExerciseProps) {
    console.log("rendering PistonExercise");

    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [results, setResults] = useState<any>({});
    const codeEditorRef = useRef<{ getValue: () => string } | null>(null);

    const theme = useSelector(selectTheme);
    const {lesson} = useContext(lessonContext);
    const {enrollment} = useContext(enrollmentContext);
    const {workshop} = useContext(workshopContext);
    const currentUser = useSelector(selectCurrentUser);
    const params = useParams<{ workshopId?: string }>();

    useEffect(() => {
        setSubmitted(false);
        setResults({});
        let elementById = document.getElementById('progress-tab');
        if (elementById) elementById.scrollTop = 0;
    }, [exercise.id, enrollmentExercise]);

    const monacoTheme = theme === "dark" ? "vs-dark" : "light";
    const {code: enrollmentCode, testResults} = enrollmentExercise.latestSubmission || {};
    const monacoCode = enrollmentCode || exercise.data.starterCode;
    const [resultsTab, setResultsTab] = useState<string | null>('progress');

    const submit = async (code: string) => {
        setSubmitting(true);
        setResults({});
        const submitCode = functions.httpsCallable('submitPistonCode');
        try {
            const result = (await submitCode({code, exerciseId: exercise.id, language: exercise.data.language})).data;
            setResults(result);
            setSubmitting(false);
            setSubmitted(true);
            setResultsTab(result.raw.stderr ? 'output' : 'progress');

            const firstFailedTest = result.tests.findIndex((t: any) => t.result === "failed") + 1;
            let elementById = document.getElementById('progress-tab');
            if (elementById) {
                if (firstFailedTest > 0) {
                    const sectionDiv = document.getElementById(`section-${firstFailedTest}`);
                    if (sectionDiv) elementById.scrollTop = sectionDiv.offsetTop - 60;
                } else {
                    elementById.scrollTop = 0;
                }
            }

            submitTestResults(code, result);
        } catch (e) {
            console.log(e);
            alert(e.message);
        }
    }

    const submitTestResults = (code: string, testResults: any) => {
        if (code) {
            const data: any = {
                user: currentUser,
                lessonId: lesson!.id,
                exerciseId: exercise.id,
                exerciseType: exercise.data.type,
                language: exercise.data.language,
                currentAttempt: enrollment!.data.currentAttempt,
                code,
                testResults
            };
            if (workshop && params.workshopId) {
                data.clientId = workshop.data.client.id;
                data.workshopId = workshop.id;
            }
            return createSubmission(data);
        }
    };

    const failedCount = results?.raw?.stderr ? exercise.data.sections.length : exercise.data.sections.reduce((result, section) => {
        if (section.type === "requirement") {
            const testResultSource = submitted ? results : testResults;
            const testResult = testResultSource?.tests?.find((test: any) => test.name === section.testName);
            if (testResult?.result === "failed") result++;
        }
        return result;
    }, 0);

    const failureBadge = failedCount ? <Badge variant="danger">{failedCount}</Badge> : "";

    return (
        <Container fluid>
            <Row>
                <Col xs={4} className={"border-right pt-2"}>
                    <Tabs>
                        <Tab eventKey="description"
                             style={{
                                 "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight})`,
                                 "overflow": "auto"
                             }}
                             title="Description"
                             className={"pt-2 pr-2"}>
                            <h3 className={"mb-5 mt-3 pb-1 border-bottom"}>{exercise.data.name}</h3>
                            {
                                exercise.data.sections.map((section, i) => {
                                    let content = null;
                                    if (section.type === "markdown") {
                                        content = <Markdown markdown={section.text}/>
                                    }

                                    return (
                                        <div key={i}>
                                            {content}
                                        </div>
                                    );
                                })
                            }
                        </Tab>
                        {
                            lesson!.data.cheatSheet && (
                                <Tab eventKey="cheatsheet"
                                     title="Cheat Sheet"
                                     style={{
                                         "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight})`,
                                         "overflow": "auto"
                                     }}
                                     className={"pt-2 pr-2"}>
                                    <Markdown markdown={lesson!.data.cheatSheet}/>
                                </Tab>
                            )
                        }
                    </Tabs>
                </Col>
                <Col xs={4} className={"border-right col-editor"}>
                    <Editor
                        className={"border"}
                        height={`calc(100vh - ${navBarHeight} - ${footerHeight} - ${runBarHeight})`}
                        theme={monacoTheme}
                        defaultLanguage="java"
                        onMount={(editor, monaco) => {
                            codeEditorRef.current = editor;

                            editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
                                submit(editor.getValue());
                            });
                            editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, () => {
                                submit(editor.getValue());
                            });
                        }}
                        value={monacoCode}
                        options={{
                            minimap: {
                                enabled: false,
                            },
                            fontSize: 14,
                            scrollBeyondLastLine: false,
                        }}
                    />
                    <div style={{height: runBarHeight}} className="pt-2">
                        <Button variant={"primary"}
                                disabled={submitting}
                                style={{width: "10em"}}
                                onClick={() => submit(codeEditorRef.current!.getValue())}>
                            Run {submitting && <Spinner animation={"border"} size={"sm"}/>}
                        </Button>
                    </div>
                </Col>
                <Col xs={4} className={"pt-2"}>
                    <Tabs activeKey={resultsTab} onSelect={(k) => setResultsTab(k)}>
                        <Tab eventKey="progress" title={<>Progress {failureBadge}</>} className={"pt-2"}>
                            <div id={"progress-tab"}
                                 className={"pr-2"}
                                 style={{
                                     "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight} - ${paddingHeight})`,
                                     "overflow": "auto"
                                 }}
                            >
                                {
                                    exercise.data.sections.map((section, i) => {
                                        let content = null;
                                        if (section.type === "requirement") {
                                            const testResultSource = submitted ? results : testResults;
                                            const testResult = testResultSource?.tests?.find((test: any) => test.name === section.testName);
                                            content = <Section section={section}
                                                               id={`section-${i}`}
                                                               hasStdErr={!!results.raw?.stderr}
                                                               testResult={testResult}
                                                               submitting={submitting}/>
                                        }

                                        return (
                                            <div key={i}>
                                                {content}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </Tab>
                        <Tab eventKey="output"
                             title="Console Output"
                             style={{
                                 "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight})`,
                                 "overflow": "auto"
                             }}
                             className={"pt-2"}>
                            {
                                !!results.raw?.stdout && (
                                    <pre><code>{results.raw.stdout}</code></pre>
                                )
                            }
                            {
                                !!results.raw?.stderr && (
                                    <pre><code>{results.raw.stderr}</code></pre>
                                )
                            }
                            {
                                !results.raw?.stdout && !results.raw?.stderr && (
                                    <pre><code>No output yet. Click "Run" to generate output.</code></pre>
                                )
                            }
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
            <Row style={{height: footerHeight}} className={"navbar-themed pt-2 border-top"}>
                <Col xs={3} className={"text-left"}>
                </Col>
                <Col xs={6} className={"text-center"}>
                    {
                        previousUrl && (
                            <Link style={{width: "10em"}} className={"btn btn-warning d-inline-block mr-5"}
                                  to={previousUrl}>
                                <FontAwesomeIcon icon={faChevronLeft}/> Previous
                            </Link>
                        )
                    }
                    {
                        !previousUrl && (
                            <button style={{width: "10em"}} disabled
                                    className={"btn btn-warning d-inline-block mr-5"}>
                                <FontAwesomeIcon icon={faChevronLeft}/> Previous
                            </button>
                        )
                    }
                    {
                        nextUrl && (
                            <Link style={{width: "10em"}} className={"btn btn-warning d-inline-block ml-5"}
                                  to={nextUrl}>
                                Next <FontAwesomeIcon icon={faChevronRight}/>
                            </Link>
                        )
                    }
                    {
                        !nextUrl && (
                            <button style={{width: "10em"}} disabled
                                    className={"btn btn-warning d-inline-block ml-5"}>
                                Next <FontAwesomeIcon icon={faChevronRight}/>
                            </button>
                        )
                    }
                </Col>
                <Col xs={3} className={"text-right"}>
                </Col>
            </Row>
        </Container>
    )
}

export default PistonExercise;
