import React, { useContext, useEffect, useState } from "react";
import CFUModal from "../exercises/CFUModal";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import CodeWithTestsExercise from "../exercises/CodeWithTestsExercise";
import StackblitzExercise from "../exercises/StackblitzExercise";
import {
    enrollmentContext,
    lessonContext, withAttendance, withEnrollment,
    withExercise,
    withWorkshop,
    workshopContext
} from "../../components/contexts";
import { selectCurrentUser } from "../auth/auth-slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import PistonExercise from "../exercises/PistonExercise";
import { watchDeliveredExercises } from "./workshop-api";
import { Document, Exercise, ExerciseAttempt } from "../../../../types";


const types: {[key: string]: any} = {
    "code-with-tests": CodeWithTestsExercise,
    "tdd": CodeWithTestsExercise,
    "piston": PistonExercise,
    "stackblitz": StackblitzExercise,
};

type WorkshopExerciseProps = {
    exercise: Document<Exercise>,
    enrollmentExercise: any,
    previousExerciseId: string,
    nextExerciseId: string
}
function WorkshopExercise({exercise, enrollmentExercise, previousExerciseId, nextExerciseId}: WorkshopExerciseProps) {
    console.log("rendering WorkshopExercise");

    const {workshop} = useContext(workshopContext);
    const {lesson} = useContext(lessonContext);
    const {enrollment} = useContext(enrollmentContext);
    const currentUser = useSelector(selectCurrentUser);

    const [lastCfuId, setLastCfuId] = useState<string | null>(null);
    const [deliveredCfus, setDeliveredCfus] = useState<string[]>([]);

    useEffect(
        () => watchDeliveredExercises(workshop!, currentUser!, setDeliveredCfus),
        [workshop, currentUser]
    );

    const currentAttempt = enrollment!.data.attempts[enrollment!.data.currentAttempt];
    const answeredCfus: {[key: string]: ExerciseAttempt} = {};
    for (const [id, response] of Object.entries(currentAttempt.exercises)) {
        if (response.type === "multiple-choice") {
            answeredCfus[id] = response;
        }
    }

    const firstUnansweredCfuId = deliveredCfus.find(id => !answeredCfus[id]);
    const firstUnansweredCfu = lesson!.data.exercises.find(exercise => exercise.id === firstUnansweredCfuId);
    const lastCfu = lesson!.data.exercises.find(exercise => exercise.id === lastCfuId);
    console.log("lastCfuId", lastCfuId);
    console.log("lastCfu", lastCfu);
    console.log("firstUnansweredCfu", firstUnansweredCfu);
    const displayCfu = firstUnansweredCfu || lastCfu;

    const breadcrumb = <Breadcrumb listProps={{className: "rounded-0 bg-transparent p-1 pl-3"}}>
        <Breadcrumb.Item linkAs={Link}
                         linkProps={{to: `/`}}><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link}
                         linkProps={{to: `/workshops/${workshop!.id}`}}>{workshop!.data.lesson.name}</Breadcrumb.Item>
        <Breadcrumb.Item active>{exercise.data.name}</Breadcrumb.Item>
    </Breadcrumb>;

    let previousLink;
    let previousUrl;
    if (previousExerciseId) {
        previousUrl = `/workshops/${workshop!.id}/exercises/${previousExerciseId}`;
        previousLink = (
            <Link className={"d-inline-block mr-2"}
                  title={"Previous Exercise"}
                  to={previousUrl}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </Link>
        );
    } else {
        previousLink = <FontAwesomeIcon color={"#ccc"}
                                        className={"d-inline-block mr-2"}
                                        icon={faChevronLeft}/>;
    }

    let nextLink;
    let nextUrl;
    if (nextExerciseId) {
        nextUrl = `/workshops/${workshop!.id}/exercises/${nextExerciseId}`;
        nextLink = (
            <Link className={"d-inline-block mr-2"}
                  title={"Next Exercise"}
                  to={nextUrl}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </Link>
        );
    } else {
        nextLink = <FontAwesomeIcon color={"#ccc"}
                                    className={"d-inline-block mr-2"}
                                    icon={faChevronRight}/>;
    }

    const Component = types[exercise.data.type];

    return (
        <>
            {
                displayCfu && (
                    <CFUModal exercise={displayCfu}
                              setLastCfuId={setLastCfuId}/>
                )
            }

            <Component exercise={exercise}
                       enrollmentExercise={enrollmentExercise}
                       nextUrl={nextUrl}
                       nextLink={nextLink}
                       previousUrl={previousUrl}
                       previousLink={previousLink}
                       breadcrumb={breadcrumb}
            />
        </>
    );
}

export default withWorkshop(withEnrollment(withAttendance(withExercise(WorkshopExercise))));
