import React, { useEffect, useState } from "react";
import { watchWorkshops } from "./workshop-api";
import { Link } from "react-router-dom";
import { Workshop, Document } from "../../../../types";

type WorkshopRowProps = { workshop: Document<Workshop> }

function WorkshopRow({workshop}: WorkshopRowProps) {
    const {id, data: {date, lesson: {name: lessonName}, client: {name: clientName}}} = workshop;
    return (
        <tr>
            <td><Link to={`/admin/workshops/${id}`}>{id}</Link></td>
            <td>{lessonName}</td>
            <td>{clientName}</td>
            <td>{date}</td>
        </tr>
    );
}

function WorkshopList() {
    const [workshops, setWorkshops] = useState<Document<Workshop>[]>([]);

    useEffect(() => {
        return watchWorkshops(workshops => setWorkshops(workshops));
    }, []);

    if (!workshops.length) return <div>No workshops to display</div>;

    return (
        <table className="table table-sm table-striped table-bordered">
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Client ID</th>
                <th>Date</th>
            </tr>
            </thead>
            <tbody>
            {workshops.map(workshop => <WorkshopRow key={workshop.id} workshop={workshop}/>)}
            </tbody>
        </table>
    );
}

export default WorkshopList;
