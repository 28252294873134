import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import CodeWithTestsExercise from "../exercises/CodeWithTestsExercise";
import MultipleChoiceExerciseComponent from "../exercises/MultipleChoiceExerciseComponent";
import StackblitzExercise from "../exercises/StackblitzExercise";
import { lessonContext, withEnrollment, withExercise, withLesson } from "../../components/contexts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import PistonExercise from "../exercises/PistonExercise";
import { Document, Exercise } from "../../../../types";

const types = {
    "code-with-tests": CodeWithTestsExercise,
    "tdd": CodeWithTestsExercise,
    "multiple-choice": MultipleChoiceExerciseComponent,
    "piston": PistonExercise,
    "stackblitz": StackblitzExercise,
};

type LessonExerciseProps = {
    exercise: Document<Exercise>,
    enrollmentExercise: any,
    previousExerciseId: string,
    nextExerciseId: string
}
function LessonExercise({ exercise, enrollmentExercise, previousExerciseId, nextExerciseId }: LessonExerciseProps) {
    console.log("rendering LessonExercise", exercise.id);
    const { lesson } = useContext(lessonContext);

    const breadcrumb = (
        <Breadcrumb listProps={{ className: "rounded-0 bg-transparent p-1 pl-3 pb-0 mb-0" }}>
            <Breadcrumb.Item linkAs={Link}
                             linkProps={{ to: `/` }}><FontAwesomeIcon icon={faHome}/></Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link}
                             linkProps={{ to: `/lessons/${lesson!.id}` }}>{lesson!.data.name}</Breadcrumb.Item>
            <Breadcrumb.Item active>{exercise.data.name}</Breadcrumb.Item>
        </Breadcrumb>
    );

    let previousLink;
    let previousUrl = "";
    if (previousExerciseId) {
        previousUrl = `/lessons/${lesson!.id}/exercises/${previousExerciseId}`;
        previousLink = (
            <Link className={"d-inline-block mr-2"}
                  title={"Previous Exercise"}
                  to={previousUrl}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </Link>
        );
    } else {
        previousLink = <FontAwesomeIcon color={"#ccc"}
                                        className={"d-inline-block mr-2"}
                                        icon={faChevronLeft}/>;
    }

    let nextLink;
    let nextUrl = "";
    if (nextExerciseId) {
        nextUrl = `/lessons/${lesson!.id}/exercises/${nextExerciseId}`;
        nextLink = (
            <Link className={"d-inline-block mr-2"}
                  title={"Next Exercise"}
                  to={nextUrl}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </Link>
        );
    } else {
        nextLink = <FontAwesomeIcon color={"#ccc"}
                                    className={"d-inline-block mr-2"}
                                    icon={faChevronRight}/>;
    }

    const Component = types[exercise.data.type];

    return (
        <Component exercise={exercise}
                   enrollmentExercise={enrollmentExercise}
                   nextUrl={nextUrl}
                   nextLink={nextLink}
                   previousUrl={previousUrl}
                   previousLink={previousLink}
                   breadcrumb={breadcrumb}
        />
    );
}

export default withLesson(withEnrollment(withExercise(LessonExercise)));
