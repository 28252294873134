import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../features/auth/auth-slice";
import Container from "react-bootstrap/Container";
import { db, FieldPath } from "../services/firebase";
import { Client, Document, Membership, ProgressSummary } from "../../../types";

type MemberInfoProps = { id: string, membership: Membership, summary: ProgressSummary };

function nameOf(membership: Membership) {
    return membership.displayName || membership.email;
}

function MemberInfo({membership, summary}: MemberInfoProps) {
    return (
        <div className={"border rounded p-2 mb-4"}>
            <p><strong>{nameOf(membership)} - {membership.roles.join(", ")}</strong></p>
            {
                Object.values((summary?.lessons || [])).map(lessonSummary => {
                    // TODO: show in-progress and complete in different colors
                    return (
                        <div className={"border-bottom pt-3 pb-3"} key={lessonSummary.lesson.id}>
                            {lessonSummary.lesson.name}: {lessonSummary.status}
                            <br/>
                            Submission
                            Count: {lessonSummary.exercises.reduce((sum, exercise) => sum + exercise.submissionCount, 0)}
                        </div>
                    )
                })
            }
        </div>
    )
}

type ClientSummaryProps = { client: Document<Client>, clientSummaries: { [key: string]: ProgressSummary } };

function ClientSummary({client, clientSummaries}: ClientSummaryProps) {
    return (
        <div>
            <h2>{client.data.name}</h2>
            <p><strong>Invitation Tokens</strong></p>
            <p>{Object.keys(client.data.invitationTokens).join(", ")}</p>
            <p><strong>Students</strong></p>
            <div>
                {
                    Object.entries(client.data.users || {}).map(([id, membership]) => {
                        return <MemberInfo key={id}
                                           id={id}
                                           summary={(clientSummaries || {})[id]}
                                           membership={membership}/>;
                    })
                }
            </div>
        </div>
    )
}

function ManagerDashboard() {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser)!;
    const [clients, setClients] = useState<Document<Client>[]>([]);
    const [summaries, setSummaries] = useState<{ [key: string]: { [key: string]: ProgressSummary } }>({});

    useEffect(() => {
        (async () => {
            if (currentUser.claims && currentUser.claims.clients) {
                const clientsSnapshot = await db.collection("clients")
                                                .where(FieldPath.documentId(), "in", Object.keys(currentUser.claims.clients))
                                                .get();
                const dbClients: Document<Client>[] = [];
                clientsSnapshot.forEach(doc => dbClients.push({id: doc.id, data: doc.data() as Client}));
                setClients(dbClients);

                const summaries: { [key: string]: { [key: string]: ProgressSummary } } = {};
                for (const client of dbClients) {
                    const summarySnapshots = await db.collection("clients")
                                                     .doc(client.id)
                                                     .collection("summaries")
                                                     .get();
                    const clientSummaries: { [key: string]: ProgressSummary } = {};
                    summarySnapshots.forEach(doc => clientSummaries[doc.id] = doc.data() as ProgressSummary);
                    summaries[client.id] = clientSummaries;
                }
                setSummaries(summaries);
            }
        })();
    }, [dispatch, currentUser]);

    return (
        <Container className={"mt-5"}>
            {
                clients.filter(client => summaries[client.id]).map(client => (
                    <ClientSummary key={client.id}
                                   clientSummaries={summaries[client.id]}
                                   client={client}/>
                ))
            }
        </Container>
    );
}

export default ManagerDashboard;
