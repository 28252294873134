import React, { FormEvent } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { auth, googleProvider } from "../../services/firebase";
import Button from "react-bootstrap/Button";

function AdminLogin() {
    const login = async (e: FormEvent) => {
        e.preventDefault();
        try {
            await auth.signInWithRedirect(googleProvider);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`${errorCode} - ${errorMessage}`);
        }
    };

    return (
        <Row className={"mt-5"}>
            <Col md={{ span: 6, offset: 3 }}>
                <form onSubmit={login}>
                    <h3>Admin Login</h3>
                    <Button type={"submit"}>Login With Google</Button>
                </form>
            </Col>
        </Row>
    );
}

export default AdminLogin;
