import React, { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchLesson } from "../features/lessons/lesson-api";
import { selectCurrentUser } from "../features/auth/auth-slice";
import { fetchOrCreateAttendance, fetchOrCreateEnrollment } from "../features/students/student-api";
import { fetchWorkshopAsStudent } from "../features/workshops/workshop-api";
import { Spinner } from "react-bootstrap";
import { Attendance, Document, Enrollment, Lesson, LessonWithExercises, Workshop } from "../../../types";

type LessonContext = { lesson: Document<LessonWithExercises> | null };
export const lessonContext = createContext<LessonContext>({lesson: null});
const LessonProvider = lessonContext.Provider;

type EnrollmentContext = {
    enrollment: Document<Enrollment> | null,
    reloadEnrollment: () => Promise<void>
};
export const enrollmentContext = createContext<EnrollmentContext>({
    enrollment: null,
    reloadEnrollment: async () => {}}
);
const EnrollmentProvider = enrollmentContext.Provider;

type WorkshopContext = { workshop: Document<Workshop> | null }
export const workshopContext = createContext<WorkshopContext>({workshop: null});
const WorkshopProvider = workshopContext.Provider;

type AttendanceContext = { attendance: Document<Attendance> | null }
export const attendanceContext = createContext<AttendanceContext>({attendance: null});
const AttendanceProvider = attendanceContext.Provider;

export const withLesson = (Component: React.FC) => (props: object) => {
    console.log("rendering withLesson");
    const params = useParams<{ lessonId: string }>();
    const currentUser = useSelector(selectCurrentUser)!;
    const [lesson, setLesson] = useState<Document<LessonWithExercises> | null>(null);
    const [lessonLoading, setLessonLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const lessonDoc = await fetchLesson(params.lessonId);
            if (!lessonDoc) throw new Error(`Expected to find lesson ${params.lessonId} but didn't`);
            setLesson(lessonDoc);
            setLessonLoading(false);
        })();
    }, [params.lessonId, setLessonLoading, currentUser]);

    if (lessonLoading) return <Spinner animation={"border"}/>;
    if (lesson) return (
        <LessonProvider value={{lesson}}>
            <Component {...props}/>
        </LessonProvider>
    );
    return <div>Could not find lesson</div>;
};

export const withEnrollment = (Component: React.FC) => (props: object) => {
    console.log("rendering withEnrollment");
    const params = useParams<{ lessonId: string, exerciseId?: string }>();
    const currentUser = useSelector(selectCurrentUser)!;
    const {lesson} = useContext(lessonContext);
    const [enrollment, setEnrollment] = useState<Document<Enrollment> | null>(null);
    const [loading, setLoading] = useState(true);
    const reloadEnrollment = async () => {
        setEnrollment(await fetchOrCreateEnrollment(currentUser, lesson as Document<Lesson>));
    }

    useEffect(() => {
        (async () => {
            if (lesson) {
                setLoading(true);
                setEnrollment(await fetchOrCreateEnrollment(currentUser, lesson as Document<Lesson>));
                setLoading(false);
            }
        })();
    }, [params.lessonId, params.exerciseId, currentUser, lesson]);

    if (loading) return <Spinner animation={"border"}/>;
    if (lesson) return (
        <EnrollmentProvider value={{enrollment, reloadEnrollment}}>
            <Component {...props}/>
        </EnrollmentProvider>
    );
    return <div>Could not find enrollment</div>;
};

export const withWorkshop = (Component: React.FC) => (props: object) => {
    console.log("rendering withWorkshop");
    const params = useParams<{ workshopId: string }>();
    const currentUser = useSelector(selectCurrentUser)!;

    const [workshop, setWorkshop] = useState<Document<Workshop> | null>(null);
    const [lesson, setLesson] = useState<Document<LessonWithExercises> | null>(null);
    const [workshopLoading, setWorkshopLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const workshop = await fetchWorkshopAsStudent(params.workshopId, Object.keys(currentUser.claims.clients!));
            if (workshop) {
                const lesson = fetchLesson(workshop.data.lesson.id);
                setWorkshop(workshop);
                setLesson(await lesson);
            }
            setWorkshopLoading(false);
        })();
    }, [currentUser, params.workshopId]);

    if (workshopLoading) return <Spinner animation={"border"}/>;
    if (workshop) return <WorkshopProvider value={{workshop}}>
        <LessonProvider value={{lesson}}>
            <Component {...props}/>
        </LessonProvider>
    </WorkshopProvider>;
    return <div>Could not find workshop</div>;
};

export const withAttendance = (Component: any) => (props: object) => {
    console.log("rendering withWorkshop");
    const params = useParams<{ workshopId: string, exerciseId?: string }>();
    const currentUser = useSelector(selectCurrentUser)!;

    const {workshop} = useContext(workshopContext);
    const [attendance, setAttendance] = useState<Document<Attendance> | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (workshop) {
                setLoading(true);
                const attendance = fetchOrCreateAttendance(workshop.id, currentUser);
                setAttendance(await attendance);
                setLoading(false);
            }
        })();
    }, [currentUser, params.workshopId, workshop, params.exerciseId]);

    if (loading) return <Spinner animation={"border"}/>;
    if (workshop) return <AttendanceProvider value={{attendance}}>
        <Component {...props}/>
    </AttendanceProvider>;
    return <div>Could not find attendance</div>;
};

// TODO: https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
export const withExercise = (Component: any) => (props: object) => {
    console.log("rendering withExercise");
    const params = useParams<{ exerciseId: string }>();
    const {lesson} = useContext(lessonContext);
    const {enrollment} = useContext(enrollmentContext);
    const {workshop} = useContext(workshopContext);

    const exerciseId = params.exerciseId;
    const exercises = workshop ?
        lesson!.data.exercises.filter(exercise => exercise.data.type !== "multiple-choice") :
        lesson!.data.exercises;

    const exercise = exercises.find(exercise => exercise.id === exerciseId);

    if (!exercise) return <div>"An error has occurred. Please refresh."</div>;

    const exerciseIds = exercises.map(exercise => exercise.id);
    const indexOfExercise = exerciseIds.indexOf(exerciseId);
    const nextExerciseId = exerciseIds[indexOfExercise + 1];
    const previousExerciseId = exerciseIds[indexOfExercise - 1];
    const attempt = (enrollment!.data.attempts || {})[enrollment!.data.currentAttempt] || {};
    const enrollmentExercise = (attempt.exercises || {})[exercise.id] || {};

    return <Component {...props}
                      exercise={exercise}
                      enrollmentExercise={enrollmentExercise}
                      previousExerciseId={previousExerciseId}
                      nextExerciseId={nextExerciseId}
    />;
};
