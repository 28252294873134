import { Attendance, CurrentUser, Document, UnsubscribeFn, Workshop } from "../../../../types";
import { db, FieldPath } from "../../services/firebase";
import { attendanceConverter } from "../students/converters";
import firebase from "firebase/compat";

export const watchWorkshops = (
    onChange: (workshops: Document<Workshop>[]) => void
): UnsubscribeFn => {
    return db.collection("workshops").onSnapshot(querySnapshot => {
        const workshops: Document<Workshop>[] = [];
        querySnapshot.forEach(doc => workshops.push({
            id: doc.id,
            data: doc.data() as Workshop,
        }));
        onChange(workshops);
    });
};

export const watchDeliveredExercises = (
    workshop: Document<Workshop>,
    currentUser: CurrentUser,
    setDeliveredCfus: (value: string[]) => void
): UnsubscribeFn => {
    return db.collection("workshops").doc(workshop.id)
             .collection("live")
             .where('clientId', 'in', Object.keys(currentUser.claims.clients || {}))
             .where(FieldPath.documentId(), '==', 'delivered-exercises')
             .onSnapshot(snapshot => {
                 let deliveredCfus: string[] = [];
                 snapshot.forEach(doc => deliveredCfus = doc.data().deliveredCfus || []);
                 setDeliveredCfus(deliveredCfus);
             });
}

export const watchAttendances = (
    workshopId: string,
    setAttendances: (attendances: Document<Attendance>[]) => void
): UnsubscribeFn => {
    return db.collection("workshops").doc(workshopId)
             .collection("attendances")
             .withConverter(attendanceConverter)
             .onSnapshot(querySnapshot => {
                 const attendances: Document<Attendance>[] = [];
                 querySnapshot.forEach(doc => attendances.push({
                     id: doc.id,
                     data: doc.data(),
                 }));
                 setAttendances(attendances);
             });
};

export const fetchWorkshopAsStudent = async (
    workshopId: string,
    clientIds: string[]
): Promise<Document<Workshop> | null> => {
    const workshopSnapshot = await db.collection("workshops")
                                     .where("client.id", "in", clientIds)
                                     .where(FieldPath.documentId(), '==', workshopId).get();
    let workshop: Document<Workshop> | null = null;
    workshopSnapshot.forEach(doc => workshop = {
        id: doc.id,
        data: {
            ...doc.data()! as Workshop,
            lesson: doc.data().lesson,
        }
    });
    return workshop;
};

export const fetchWorkshopAsInstructor = async (workshopId: string): Promise<Document<Workshop>> => {
    const snapshot = await db.collection("workshops").doc(workshopId).get() as firebase.firestore.DocumentSnapshot<Workshop>;
    return {
        id: snapshot.id,
        data: snapshot.data() as Workshop
    };
};

export const fetchWorkshopsForClient = async (clientIds: string[]) => {
    const querySnapshot = await db.collection("workshops")
                                  .where('client.id', 'in', clientIds)
                                  .get();
    const workshops: Document<Workshop>[] = [];
    querySnapshot.forEach(doc => workshops.push({
        id: doc.id,
        data: doc.data() as Workshop,
    }));
    return workshops;
};
