import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Document, Lesson } from "../../../../types";
import {fetchLessons} from "./lesson-api";

function LessonList() {
    const [lessons, setLessons] = useState<Document<Lesson>[]>([]);

    useEffect(() => {
        (async () => {
            setLessons(await fetchLessons());
        })();
    }, []);

    if (!lessons.length) return <div>No lessons to display</div>;

    return (
        <table className="table table-sm table-striped table-bordered">
            <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
            </tr>
            </thead>
            <tbody>
            {lessons.filter(lesson => lesson.data.status === "published").map(lesson => <tr key={lesson.id}>
                <td><Link to={`/admin/lessons/${lesson.id}`}>{lesson.id}</Link></td>
                <td>{lesson.data.name}</td>
            </tr>)}
            </tbody>
        </table>
    );
}

export default LessonList;
