import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurrentUser, Registration } from '../../../../types';
import { db } from "../../services/firebase";
import firebase from "firebase/compat/app";
import { AppDispatch, RootState } from "../../app/store";

type AuthState = {
    currentUser: CurrentUser | null,
    loading: boolean,
    currentUserSet: boolean,
    theme: "light" | "dark",
}

const initialState: AuthState = {
    currentUser: null,
    loading: true,
    currentUserSet: false,
    theme: "light"
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser: (state, action: PayloadAction<CurrentUser | null>) => {
            state.currentUser = action.payload;
            state.loading = false;
            state.currentUserSet = true;
        },
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
    },
});

export const {setCurrentUser, setTheme} = authSlice.actions;

export const createRegistration = (firebaseUser: firebase.User, token: string) => async (dispatch: AppDispatch) => {
    const registration: Registration = {
        id: firebaseUser.uid,
        displayName: firebaseUser.displayName,
        email: firebaseUser.email!,
        invitationToken: token,
    };
    await db.collection(`/registrations`).doc(firebaseUser.uid).set(registration);
    dispatch(setCurrentUser({
        id: firebaseUser.uid,
        email: firebaseUser.email!,
        displayName: firebaseUser.displayName,
        claims: {}
    }));
};

export const selectCurrentUser = (state: RootState) => state.auth.currentUser;
export const selectLoggedIn = (state: RootState) => !!state.auth.currentUser;
export const selectLoggedInAdmin = (state: RootState) => {
    return state.auth.currentUser && (state.auth.currentUser?.claims || {}).admin;
};
export const selectAuthLoading = (state: RootState) => state.auth.loading;
export const selectLoggedInManager = (state: RootState) => {
    const clientRoles: string[][] = Object.values(state.auth.currentUser?.claims?.clients || {});
    return state.auth.currentUser && clientRoles.some(clientRole => clientRole.includes("manager"));
};
export const selectCurrentUserSet = (state: RootState) => state.auth.currentUserSet;
export const selectTheme = (state: RootState) => state.auth.theme;

export default authSlice.reducer;
