import { Redirect, Route } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";
import { selectAuthLoading, selectLoggedInAdmin } from "./auth-slice";

type RouteProps = any;
function AdminRoute({ component: Component, ...rest }: RouteProps) {
    const authenticatedAdmin = useSelector(selectLoggedInAdmin);
    const authLoading = useSelector(selectAuthLoading);

    if (authLoading) return <div/>;

    return (
        <Route
            {...rest}
            render={(props) => authenticatedAdmin
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }}/>}
        />
    );
}

export default AdminRoute;
