import React, { useContext, useEffect, useRef, useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Editor from "@monaco-editor/react";
import { functions } from "../../services/firebase";
import TestResultList from "./TestResultList";
import { useSelector } from "react-redux";
import { selectTheme } from "../auth/auth-slice";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Markdown from "../../shared/Markdown";
import { Badge, Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { Link } from "react-router-dom";
import Section from "./Section";
import { lessonContext } from "../../components/contexts";
import { Document, Exercise } from '../../../../types';

const navBarHeight = "56px";
const footerHeight = "56px";
const runBarHeight = "56px";
const tabHeight = "56px";
const paddingHeight = "0.5rem";
const titleHeight = "56px";

type TestRunnerProps = {exercise: Document<Exercise>, code: string, tests: string, onSubmit: (x: string, y: string, z: string) => void, previousUrl: string, nextUrl: string}
function TestRunner({exercise, code, tests, onSubmit, previousUrl, nextUrl}: TestRunnerProps) {
    console.log("rendering TestRunner");
    const [apiResponse, setApiResponse] = useState<any>("");
    const [submitting, setSubmitting] = useState(false);
    const theme = useSelector(selectTheme);
    const {lesson} = useContext(lessonContext);
    const [resultsTab, setResultsTab] = useState<string | null>('progress');
    const codeEditorRef = useRef<{getValue: () => string} | null>(null);
    const testEditorRef = useRef<{getValue: () => string} | null>(null);

    useEffect(() => {
        setApiResponse("");
    }, [exercise.id]);

    const runTests = async (codeToSubmit: string, testsToSubmit: string) => {
        setSubmitting(true);
        setApiResponse("");

        console.log("code", codeToSubmit);
        console.log("tests", testsToSubmit);

        const submitCode = functions.httpsCallable('submitCode');
        try {
            const result = (await submitCode({
                code: codeToSubmit,
                tests: testsToSubmit,
                exerciseId: exercise.id
            })).data;
            console.log(result);
            setApiResponse(result);
            onSubmit(codeToSubmit, testsToSubmit, result);
        } catch (e) {
            console.log(e);
            alert(e.message);
        }
        setSubmitting(false);
    };

    const monacoTheme = theme === "dark" ? "vs-dark" : "light";

    const failedCount = 0;
    const failureBadge = failedCount ? <Badge variant="danger">{failedCount}</Badge> : "";

    return (
        <Container fluid>
            <Row>
                <Col xs={4} className={"border-right pt-2"}>

                    <Tabs>
                        <Tab eventKey="description"
                             style={{
                                 "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight})`,
                                 "overflow": "auto"
                             }}
                             title="Description"
                             className={"pt-2 pr-2"}>
                            <Markdown markdown={exercise.data.description}/>
                        </Tab>
                        <Tab eventKey="cheatsheet"
                             title="Cheat Sheet"
                             style={{
                                 "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight})`,
                                 "overflow": "auto"
                             }}
                             className={"pt-2 pr-2"}>
                            <Markdown markdown={lesson!.data.cheatSheet}/>
                        </Tab>
                    </Tabs>
                </Col>
                <Col xs={4} className={"border-right col-editor"}>
                    <div style={{height: `calc((100vh - ${navBarHeight} - ${footerHeight} - ${runBarHeight}) / 2)`}}>
                        <p><strong>Code</strong></p>
                        <Editor
                            className={"border"}
                            height={`calc(100% - ${titleHeight})`}
                            theme={monacoTheme}
                            defaultLanguage="javascript"
                            value={code}
                            onMount={(editor, monaco) => {
                                codeEditorRef.current = editor;
                                editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
                                    runTests(editor.getValue(), testEditorRef.current!.getValue());
                                });
                                editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, () => {
                                    runTests(editor.getValue(), testEditorRef.current!.getValue());
                                });
                            }}
                            options={{
                                minimap: {
                                    enabled: false,
                                },
                                fontSize: 14,
                                scrollBeyondLastLine: false,
                            }}
                        />
                    </div>
                    <div style={{height: `calc((100vh - ${navBarHeight} - ${footerHeight} - ${runBarHeight}) / 2)`}}>
                        <p><strong>Tests</strong></p>
                        <Editor
                            className={"border"}
                            height={`calc(100% - ${titleHeight})`}
                            theme={monacoTheme}
                            defaultLanguage="javascript"
                            value={tests}
                            onMount={(editor, monaco) => {
                                testEditorRef.current = editor;
                                editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, () => {
                                    runTests(codeEditorRef.current!.getValue(), editor.getValue());
                                });
                                editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, () => {
                                    runTests(codeEditorRef.current!.getValue(), editor.getValue());
                                });
                            }}
                            options={{
                                minimap: {
                                    enabled: false,
                                },
                                fontSize: 14,
                                scrollBeyondLastLine: false,
                            }}
                        />
                    </div>
                    <div style={{height: runBarHeight}} className="pt-2">
                        <Button
                            size={"sm"}
                            onClick={() => runTests(codeEditorRef.current!.getValue(), testEditorRef.current!.getValue())}
                            className={"d-inline-block mr-2"}
                            style={{width: "10em"}}
                            disabled={submitting}>
                            Run {submitting && <Spinner animation={"border"} size={"sm"}/>}
                        </Button>
                    </div>
                </Col>
                <Col xs={4} className={"pt-2"}>
                    <Tabs activeKey={resultsTab} onSelect={(k) => setResultsTab(k)}>
                        <Tab eventKey="progress" title={<>Progress {failureBadge}</>} className={"pt-2"}>
                            <div id={"progress-tab"}
                                 className={"pr-2"}
                                 style={{
                                     "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight} - ${paddingHeight})`,
                                     "overflow": "auto"
                                 }}
                            >
                                {
                                    exercise.data.sections.map((section, i) => {
                                        let content = null;
                                        if (section.type === "requirement") {
                                            const testResult = apiResponse?.system?.tests?.find((t: any) => t.fullName === section.testName);
                                            content = <Section section={section}
                                                               id={`section-${i}`}
                                                               hasStdErr={false}
                                                               testResult={testResult}
                                                               submitting={submitting}/>
                                        }

                                        return (
                                            <div key={i}>
                                                {content}
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </Tab>
                        <Tab eventKey={"userResults"}
                             title="Your Tests"
                             style={{
                                 "height": `calc(100vh - ${navBarHeight} - ${footerHeight} - ${tabHeight})`,
                                 "overflow": "auto"
                             }}
                             className={"pt-2"}>
                            {
                                apiResponse && apiResponse.user && (
                                    <TestResultList data={apiResponse.user}/>
                                )
                            }

                        </Tab>
                    </Tabs>
                </Col>
            </Row>

            <Row style={{height: footerHeight}} className={"navbar-themed pt-2 border-top"}>
                <Col xs={3} className={"text-left"}>
                </Col>
                <Col xs={6} className={"text-center"}>
                    {
                        previousUrl && (
                            <Link style={{width: "10em"}} className={"btn btn-warning d-inline-block mr-5"}
                                  to={previousUrl}>
                                <FontAwesomeIcon icon={faChevronLeft}/> Previous
                            </Link>
                        )
                    }
                    {
                        !previousUrl && (
                            <button style={{width: "10em"}} disabled
                                    className={"btn btn-warning d-inline-block mr-5"}>
                                <FontAwesomeIcon icon={faChevronLeft}/> Previous
                            </button>
                        )
                    }
                    {
                        nextUrl && (
                            <Link style={{width: "10em"}} className={"btn btn-warning d-inline-block ml-5"}
                                  to={nextUrl}>
                                Next <FontAwesomeIcon icon={faChevronRight}/>
                            </Link>
                        )
                    }
                    {
                        !nextUrl && (
                            <button style={{width: "10em"}} disabled
                                    className={"btn btn-warning d-inline-block ml-5"}>
                                Next <FontAwesomeIcon icon={faChevronRight}/>
                            </button>
                        )
                    }
                </Col>
                <Col xs={3} className={"text-right"}>

                </Col>
            </Row>
        </Container>
    );
}

export default TestRunner;
