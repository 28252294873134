import React, { FormEvent, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Markdown from "../../shared/Markdown";
import { answerVerifiers } from "../students/student-api";
import SingleLineMarkdown from "../../shared/SingleLineMarkdown";
import { CFUType, MultipleChoiceExercise, Document } from "../../../../types";

type MultipleChoiceProps = { onAnswered: any, answeredAlready: boolean, exercise: Document<MultipleChoiceExercise> };
const MultipleChoice = ({onAnswered, answeredAlready, exercise}: MultipleChoiceProps) => {
    const [currentResponse, setCurrentResponse] = useState("");
    const [hasAnswered, setHasAnswered] = useState(answeredAlready);

    useEffect(() => {
        setCurrentResponse("");
        setHasAnswered(answeredAlready);
    }, [exercise.id, answeredAlready]);

    const verifier = answerVerifiers[exercise.data.type as CFUType];
    const correct = verifier(exercise, currentResponse);

    const {inputs, description} = exercise.data;
    const correctAnswer = exercise.data.inputs.find(input => input.correct)!.answer;

    const handleRadioChange = (e: any) => {
        if (!hasAnswered) {
            setCurrentResponse(e.target.value);
        }
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setHasAnswered(true);
        onAnswered(currentResponse, correct);
    };

    return <div>
        {hasAnswered && currentResponse && correct && (
            <div className="alert alert-success">Congratulations.</div>
        )}
        {hasAnswered && currentResponse && !correct && (
            <div className="alert alert-warning">Not quite there, maybe next time!</div>
        )}

        <Markdown markdown={description}/>

        <Form onSubmit={onSubmit}>
            <Form.Group controlId="multiple-choice-selection">
                <Form.Label>Select an answer:</Form.Label>
                {(inputs || []).map((input: any, idx: number) => {
                    let className = "";
                    let suffix;
                    if (hasAnswered) {
                        if (input.correct) {
                            className = "text-success";
                            suffix = <span className="text-success d-inline-block ml-2">(correct)</span>;
                        }
                        if (input.answer !== correctAnswer && input.answer === currentResponse) {
                            className = "text-danger";
                            suffix = <span className="text-danger d-inline-block ml-2">(your answer)</span>;
                        }
                    }

                    return <div key={idx}>
                        <Form.Check
                            className={`${className} d-inline-block`}
                            type="radio"
                            label={<SingleLineMarkdown markdown={input.label}/>}
                            id={`radio-option-${idx}`}
                            checked={currentResponse ? currentResponse === input.answer : hasAnswered && !!input.correct}
                            value={input.answer}
                            name="multiple-choice-response"
                            onChange={handleRadioChange}/>
                        {suffix}
                    </div>;
                })}
            </Form.Group>
            <Form.Group controlId="submit-multiple-choice-exercise">
                <Button type="submit" disabled={currentResponse === null || hasAnswered}>
                    Submit
                </Button>
            </Form.Group>

            {exercise.data.explanation && hasAnswered && (
                <div>
                    <p><strong>Explanation</strong></p>
                    <Markdown markdown={exercise.data.explanation}/>
                </div>
            )}

        </Form>
    </div>;
};

export default MultipleChoice;
